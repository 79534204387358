import { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import InputField from "../../components/form/InputField";
import { Form, Formik } from "formik";



const FastCheckoutPhoneUpdate = ({ fastCheckOutData, fetchChildAndParentDetails, setEnableDeclarationChecks }) => {

    const [edit, setEdit] = useState(false)

    useEffect(() => {
        if (edit) {
            setEnableDeclarationChecks(true)
        } else {
            setEnableDeclarationChecks(false)
        }
    }, [edit])
    
    return (
        <>
            <Accordion.Header>Phone</Accordion.Header>
            <Accordion.Body>
                <Formik
                    initialValues={{
                        phone: fastCheckOutData?.phone
                    }}
                    onSubmit={values => console.log(values)}

                >
                    {({ values, setFieldValue, errors, touched, resetForm }) => (
                        <Form className="row g-3">
                            <div className="admin-detail-row">
                                <div className="admin-detail-cell" >
                                    <label>Phone:</label>
                                    {!edit
                                        ? <span className="item-entry" >
                                            {fastCheckOutData?.phone}
                                        </span>
                                        : <span className="item-entry" style={{ width: '100%' }}>
                                            <InputField
                                                fieldName='phone'
                                                value={values.phone}
                                                className='frm-cell'
                                                fieldType='text'
                                                placeholder='Phone'
                                                required
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </span>}

                                </div>
                                <div className="admin-detail-cell" ></div>
                                {false && <div className="admin-detail-cell" style={{ alignItems: 'flex-end' }}>
                                    {!edit ?
                                        <Button onClick={() => setEdit(true)}>Edit</Button> :
                                        <div style={{ display: 'flex', }}>
                                            <Button>VERIFY</Button>
                                            <div style={{ margin: '0 5px' }}></div>
                                            <Button onClick={() => { resetForm(); setEdit(false) }}>Reset</Button>
                                        </div>
                                    }
                                </div>}
                            </div>
                        </Form>)}
                </Formik>
            </Accordion.Body>
        </>
    )
}

export default FastCheckoutPhoneUpdate;