import { useState, useEffect, useRef } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';

import Layout from "../../admin/layout"
import FeeFilterApp from "./feeFilterApp";
import FeeTableApp from "./feeTableApp";
import { getFindFeePyemnts, getSearchItems } from "../../../utils/services";
import Loader, { hideLoader, showLoader } from "../../../common/Loader"

import { OPERATORS } from "../../../constants/app";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const SuperAdminFee = () => {

    const [data, setData] = useState([])
    const [searchItems, setSearchItems] = useState([]);
    const [schoolNameId, setSchoolNameId] = useState('')
    const [session, setSession] = useState('')
    const [filterStatus, setFilterStatus] = useState(true)
    const [activePage, setActivePage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [filter, setFilter] = useState([])

    const sessionOption = localStorage.getItem("admissionSession").split(",");
    const dispatch = useDispatch()

    const handleOnSelect = (item) => {
        if (item.id) {
            setSchoolNameId(item.id)
        }
    };

    const handleOnSearch = (item) => {

    }

    const handleClear = (item) => {
        setData([]);
        setSchoolNameId('');
        setSession('')
    }

    const handleApply = (e) => {
        setFilterStatus(false)
        e.preventDefault()
        const filters = []
        if (schoolNameId !== null && schoolNameId !== '') {
            filters.push({
                field: 'schoolId',
                operator: OPERATORS.EQUALS,
                value: schoolNameId
            });
        }
        if (session !== null && session !== '') {
            filters.push({
                field: 'session',
                operator: OPERATORS.EQUALS,
                value: session
            });
        }
        fetchRowData(filters)
    }

    const fetchRowData = async (filters , currentPage) => {
        showLoader(dispatch)
        const pageNumber = currentPage - 1;
        let filterPayload = {}
        filterPayload["filters"] = filters;
        filterPayload['limit'] = 10;
        filterPayload['offset'] = pageNumber || 0;
        try {
            const resp = await getFindFeePyemnts(filterPayload)
            setData(resp.data.schoolFeeDtos)
            setActivePage(currentPage || 1);
            setTotalRows(resp.data.count || 0);
            setFilter(filters)
            hideLoader(dispatch)
        } catch (error) {
            console.log(error)
            toast.error(error.message)
            setData([])
            hideLoader(dispatch)

        }
    }
    useEffect(() => {
        if (data && data.length>0){fetchRowData(filter, activePage)}
    }, [activePage])

    const fetchSearchItem = async () => {
        const response = await getSearchItems()
        if (response) {
            setSearchItems(response.data.map((val, index) => ({ name: val.name, id: val.schoolId })))
        } else {
            console.log('NO DATA ')
        }
    }

    useEffect(() => {
        fetchSearchItem()
    }, [])

    return (
        <Layout>
            <div className='content-area-inner manage-application inner-page-outer'>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '5px 20px' }}>
                    <div className="search-inner" style={{ width: 500,position: "relative"}}>
                    <span style={{ position: "absolute", left: "0",  top: "10px", color: "#f06070" }}>*</span>
                    <div style={{ marginLeft: "10px"  }}>
                        <ReactSearchAutocomplete
                            items={searchItems}
                            onSelect={handleOnSelect}
                            onSearch={handleOnSearch}
                            onClear={handleClear}
                            styling={{ zIndex: 4 }}
                            placeholder='Search Schools'
                        />
                        </div>
                    </div>
                    <div className="search-inner" >
                        <Form.Group className='form-element-group' controlId='' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', }}>
                            <Form.Label className='form-label' style={{ marginRight: '10px', }}><strong><span style={{color: "#f06070"}}>*</span>Session:</strong></Form.Label>
                            <Form.Select
                                value={session}
                                onChange={(e) => {
                                    setSession(e.target.value);
                                }}
                                size="sm"
                            >
                                <option>Select Session</option>
                                {sessionOption.map((val, i) => <option key={i} value={val}>{val}</option>)}

                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="search-inner">
                        <Button
                            component="label"
                            disabled={schoolNameId === '' || session === ''}
                            onClick={handleApply}
                        >
                            GO
                        </Button>
                    </div>
                </div>
                <Loader/>
                <div className='internal-page-wrapper two-columns'>
                    <FeeFilterApp
                        setData={setData}
                        schoolNameId={schoolNameId}
                        session={session}
                        filterStatus={filterStatus}
                        setFilterStatus={setFilterStatus}
                        fetchRowData={fetchRowData}
                    />
                    <FeeTableApp
                        row={data}
                        totalRows={totalRows}
                        activePage={activePage}
                        setActivePage={setActivePage}
                    />
                </div>
            </div>
        </Layout >
    )
}

export default SuperAdminFee;