import React, { useEffect, useState } from "react";
import { Accordion, Form, } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AlertDialog from "../../common/AlertDialog";
import { getIpAddress } from "../../utils/helper";
import {
    getFastCheckoutReview,
    getPlaceOrder,
    processOrderAfterPayment,
} from "../../utils/services";
import GenericDialog from "../GenericDialog";
import FastCheckoutCandidateDetail from "./fastCheckOutCandidateDetail";
import FastChekOutGuradianDetail from "./fastCheckOutGuardianDetail";
import FastCheckoutPhoneUpdate from "./fastCheckOutPhoneUpdate";
import Loader, { hideLoader, showLoader } from "../../common/Loader";
import { useDispatch } from "react-redux";

const FastCheckoutReviewAdmissionDialog = ({
    show,
    childId,
    handleClose,
    schoolType
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [candidateDetail, setCandidateDetail] = useState({});
    const [fastCheckOutData, setFastCheckOutData] = useState({})
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [infoDeclarationAccepted, setInfoDeclarationAccepted] = useState(false);
    const [termsPolicyDeclarationAccepted, setTermsPolicyDeclarationAccepted] =
        useState(false);
    const [checkoutButton, setCheckoutButton] = useState(false);
    const [enableDeclarationsChecks, setEnableDeclarationChecks] = useState(false)

    const responseHandler = async (e, orderId) => {
        try {
            const formData = new FormData();
            formData.append("response", JSON.stringify(e));
            const response = await processOrderAfterPayment(formData, orderId);
            console.log(response);
            navigate(`/paymentFailed?${response.data}`);
        } catch (e) {
            console.log(e);
            handleClose();
            toast.error("Payment Cancelled");
        }
    };

    async function placeOrder() {
        showLoader(dispatch)
        if (!(infoDeclarationAccepted && termsPolicyDeclarationAccepted)) {
            setAlertMessage("Please select all terms and conditions.");
            setShowAlertDialog(true);
            return;
        }
        try {
            const ip = await getIpAddress();
            const payload = {
                childId: childId,
                ipAddress: ip,
                deviceUserAgent: window.navigator.userAgent,
            };
            const response = await getPlaceOrder(payload);
            console.log(response);
            handleClose();
            setCheckoutButton(false);
            navigate("/paymentCheckout", { state: { data: response.data } });
            hideLoader(dispatch)
        } catch (error) {
            handleClose();
            setCheckoutButton(false);
            console.log("Error", error);
            hideLoader(dispatch)
            toast.error("Payment is failed. Please try later");
        }
    }

    const fetchChildAndParentDetails = (childId) => {
        getFastCheckoutReview(childId)
            .then((res) => {
                setCandidateDetail(res.data);
                setFastCheckOutData(res.data.fastCheckoutData)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        if (childId && childId != "" && !(schoolType === "Day School")) {
            fetchChildAndParentDetails(childId)
        }
    }, [childId]);

    useEffect(() => {
        if (show) {
            setTermsPolicyDeclarationAccepted(false);
            setInfoDeclarationAccepted(false);
        }
    }, [show]);

    return (
        <>
            <GenericDialog
                className="review-admission-modal add-child-model"
                show={show}
                handleClose={handleClose}
                modalHeader="Application Details"
            >
                <Accordion defaultActiveKey="" flush>
                    <Accordion.Item eventKey="0">
                        <FastCheckoutCandidateDetail
                            detail={candidateDetail}
                            fetchChildAndParentDetails={fetchChildAndParentDetails}
                            setEnableDeclarationChecks={setEnableDeclarationChecks}
                        />
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <FastChekOutGuradianDetail
                            fastCheckOutData={fastCheckOutData}
                            detail={candidateDetail}
                            fetchChildAndParentDetails={fetchChildAndParentDetails}
                            setEnableDeclarationChecks={setEnableDeclarationChecks}
                        />
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <FastCheckoutPhoneUpdate
                            fastCheckOutData={fastCheckOutData}
                            fetchChildAndParentDetails={fetchChildAndParentDetails}
                            setEnableDeclarationChecks={setEnableDeclarationChecks}
                        />
                    </Accordion.Item>
                </Accordion>
                <div className="declaration-wrapper">
                    <Form.Check
                        type="checkbox"
                        label=" I hereby declare that all the particulars and the documents I have provided in, or in connection with, this application are true, up-to-date and correct."
                        required
                        checked={infoDeclarationAccepted}
                        disabled={enableDeclarationsChecks}
                        onChange={(e) => {
                            setInfoDeclarationAccepted(e.target.checked);
                        }}
                    />
                    <Form.Check
                        type="checkbox"
                        label={
                            <div>
                                <span>I have read, understood and accept the </span>
                                <a href={"/termsOfService"} target="_blank">
                                    <u> Terms of Service </u>
                                </a>
                                <span> , </span>
                                <a href={"/privacyPolicy"} target="_blank">
                                    <u>Privacy Policy</u>
                                </a>
                                <span> , </span>
                                <span> and </span>
                                <a href={"/refundPolicy"} target="_blank">
                                    <u> Refund Policy</u>
                                </a>
                                <span>.</span>
                            </div>
                        }
                        required
                        disabled={enableDeclarationsChecks}
                        checked={termsPolicyDeclarationAccepted}
                        onChange={(e) => {
                            setTermsPolicyDeclarationAccepted(e.target.checked);
                        }}
                    />
                </div>
                {childId && (
                    <div className="btn-wrapper review-section-btn">
                        <Button
                            className="submit"
                            onClick={() => {
                                setCheckoutButton(true);
                                placeOrder();
                            }}
                            disabled={
                                !(
                                    infoDeclarationAccepted &&
                                    termsPolicyDeclarationAccepted &&
                                    !checkoutButton
                                )
                            }
                        >
                            Checkout
                        </Button>
                    </div>
                )}
            </GenericDialog>
            <Loader/>
            <AlertDialog
                show={showAlertDialog}
                message={alertMessage}
                handleClose={() => {setShowAlertDialog(false); hideLoader(dispatch)}}
            />
        </>
    );
};
export default FastCheckoutReviewAdmissionDialog;
