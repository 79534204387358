export const ActionTypes = {
  GET_CHILDS_LIST: "GET_CHILDS_LIST",
  CHILDS_SUCCESS: "CHILDS_SUCCESS",
  CHILDS_ERROR: "CHILDS_ERROR",
  GET_CART_ITEMS: "GET_CART_ITEMS",
  CART_LIST_SUCCESS: "CART_LIST_SUCCESS",
  CART_LIST_ERROR: "CART_LIST_ERROR",
  SET_LOCATION: "SET_LOCATION",
  GET_LOCATION: "GET_LOCATION",
  SET_LOADING: "SET_LOADING",
  GET_LOADING: "GET_LOADING",
  GET_SCHOOL_CLASS: "GET_SCHOOL_CLASS",
  GET_SCHOOL_CLASS_SUCCESS: "GET_SCHOOL_CLASS_SUCCESS",
  GET_SCHOOL_CLASS_ERROR: "GET_SCHOOL_CLASS_ERROR",
  GET_STATES: "GET_STATES",
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
  GET_STATES_ERROR: "GET_STATES_ERROR",
  GET_DISABILITIES: "GET_DISABILITIES",
  GET_DISABILITIES_SUCCESS: "GET_DISABILITIES_SUCCESS",
  GET_DISABILITIES_ERROR: "GET_DISABILITIES_ERROR",
  GET_PARENT_OCCUPATION: "GET_PARENT_OCCUPATION",
  GET_PARENT_OCCUPATION_SUCCESS: "GET_PARENT_OCCUPATION_SUCCESS",
  GET_PARENT_OCCUPATION_ERROR: "GET_PARENT_OCCUPATION_ERROR",
  SET_IS_USER_LOGGED_IN: "SET_IS_USER_LOGGED_IN",
  SET_IS_ADMIN: "SET_IS_ADMIN",
  GET_IS_USER_LOGGED_IN: "GET_IS_USER_LOGGED_IN",
  SET_SCHOOL_FILTER: "SET_SCHOOL_FILTER",
  LOGOUT: "LOGOUT",
  SET_PERMISSION_HAVE: 'SET_PERMISSION_HAVE',
  SET_GEO_LOCATION: 'SET_GEO_LOCATION',
  GET_FEES_TYPE: 'GET_FEES_TYPE',
  SET_FEES_TYPE: 'SET_FEES_TYPE',
  FEES_TYPE_ERROR: 'FEES_TYPE_ERROR',
  SET_IS_SCHOOL_NAME: 'SET_IS_SCHOOL_NAME',
  GET_IS_SCHOOL_NAME: 'GET_IS_SCHOOL_NAME',
};
