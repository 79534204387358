import Loader from "../../../../common/Loader";
import GenericDialog from "../../../../dialogs/GenericDialog";
import { formatDateToDDMMYYYY } from "../../../../utils/DateUtil";
import FeeModalHeader from "./feeModalHeader";
import footerCkoutLogo from "../../../../assets/img/brand-logo-header.svg"


export default function StudentFeeDetails({ show, handleClose, student, session, data, submissionFrequency }) {

    const getCssClassName = (curVal) => {
        switch (data[`${curVal}`].feeStatus) {
            case "Paid":
                if (data[`${curVal}`].lateFee > 0) {
                    return "status late-paid"
                } else {
                    return "status paid"
                }
                ;
            case "Due":
                return "status due";
            case "Overdue":
                return "status not-paid";
            case "Upcoming":
                return "status upcoming";
            default:
                return "status upcoming";
                ;
        }
    }

    const getFeeStatus = (curVal) => {
        switch (data[`${curVal}`].feeStatus) {
            case "Paid":
                if (data[`${curVal}`].lateFee > 0) {
                    return "Paid Late"
                } else {
                    return "Paid on Time"
                }
                ;
            case "Due":
                return "Due";
            case "Overdue":
                return "Overdue";
            case "Upcoming":
                return "Upcoming";
            default:
                return "-";
                ;
        }
    }

    return (
        <GenericDialog
            show={show}
            handleClose={handleClose}
            modalHeader="Student Fee Details"
            className="Student-fee-model"
        >
            <div className="px-n30">
                <FeeModalHeader student={student} session={session} />
                <Loader />
                <div className="table-wrapper">
                    <table className="table mb-0" style={{ width: '100%' }}>
                        <thead>
                            <tr valign="middle">
                                <th>Frequency</th>
                                <th>Status</th>
                                <th>Late Fee</th>
                                <th>Amount</th>
                                <th>Total Fee</th>
                                <th>Payment Mode</th>
                                <th>Payment Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {submissionFrequency.length > 0
                                ? submissionFrequency.map((val, key) => {
                                    return (<tr valign="middle" key={key}>
                                        <td>{val}</td>
                                        <td><div className={getCssClassName(val)}> {getFeeStatus(val)}</div></td>
                                        <td>{data[`${val}`].lateFee}</td>
                                        <td>{data[`${val}`].totalFeeDue}</td>
                                        <td>{data[`${val}`].feeStatus === 'Paid' ? data[`${val}`].paymentAmount : (data[`${val}`].lateFee + data[`${val}`].totalFeeDue)}</td>
                                        <td>{data[`${val}`].paymentMode}</td>
                                        <td>{formatDateToDDMMYYYY(data[`${val}`].paymentDate)}</td>
                                    </tr>)
                                })
                                : <tr >
                                    <td colSpan='7' style={{ textAlign: "center" }}>No Data Found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="footer-btn-wrapp">
                <div className="">
                    <img src={footerCkoutLogo} alt="fast-chekout-logo" className="img-fluid" />
                </div>
            </div>
        </GenericDialog>
    )
}