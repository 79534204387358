import { humanize } from "../../../../utils/helper";
import stdntPic from "../../../../assets/img/icons/student-pic.svg";

export default function FeeModalHeader({student, session }) {
  return(
    <div
      className='title-area stufeedetal'
      // style={{ backgroundColor: '#F0EEF2', padding: '5px', border: '1px solid lightGrey', display: 'flex', justifyContent: 'flex-start', paddingLeft:'25px' }}
    >
      <div className="img-box-container">
      <img src={stdntPic} alt="fast-chekout-logo" className="img-fluid"/>
      </div>
      <div className="tag-wrapper">
      <h2 className="title"> {`${humanize(student.firstName)} ${humanize(student.lastName)} `} </h2>
        <span className="tag-item-lbl mb-n12">
          <label>ID - {student.schoolStudentId}</label> 
        </span>
        <span className="tag-item-lbl-wrapp border-bottom-0">
        <span className="tag-item-lbl w-30n"><label>Roll No. : </label> <strong>{student.rollNo}</strong></span>
        <span className="tag-item-lbl w-70n"><label>Academic Year : </label> <strong>{session ? `(${session})` : ''}</strong></span>
        </span>
        <span className="tag-item-lbl-wrapp py-n7">
        <span className="tag-item-lbl w-30n"><label>Class : </label> <strong>{student.className}</strong></span>
        <span className="tag-item-lbl w-70n" 
          // style={{ border: '1px solid black', padding: '0px 8px 0px 8px', borderRadius: '4px', marginLeft: '5px' }}
        >
          <label 
            // style={{ color: 'grey' }}
          >Section : </label> <strong>{student.classSection}</strong>
        </span>
        </span>
      </div>
    </div>
  )
}
