import { useState } from "react";

import TableComponent from "../../../common/TableComponent";
import { humanize } from "../../../utils/helper";
import { ReactComponent as DownloadIcon } from "../../../assets/img/icons/download.svg"
import { downloadInvoiceReceipt } from "../../../utils/services";
import { toast } from "react-toastify";


const FeeTableApp = ({ row = [], setActivePage, totalRows, activePage }) => {

    const [selectedRows, setSelectedRows] = useState({});

    let rowsData = row

    console.log("row data",rowsData)

    const columns = [
        {
            accessor: 'schoolStudentId',
            Header: 'School Student Id',
        },{
            accessor: 'orderNumber',
            Header: 'Order Number',
        },{
            accessor: 'transactionId',
            Header: 'Transaction Id',
        },
        {
            accessor: 'studentName',
            Header: 'Student Name',
        },
        {
            accessor: 'className',
            Header: 'Class',
        },
        {
            accessor: 'section',
            Header: 'Section',
        },
        {
            accessor: '',
            Header: 'Fee Period',
            Cell: (e) => {
                return (<span>
                    {humanize(e?.row?.original?.monthQtr)}
                </span>)
            }
        },
        {
            accessor: 'feeAmount',
            Header: 'Fee Amount',
        },
        {
            accessor: 'lateFeeAmount',
            Header: 'Late Fee',
        },
        {
            accessor: '',
            Header: 'Total Fee',
            Cell: (e) => {
                return (<span>
                    {e?.row?.original?.lateFeeAmount + e?.row?.original?.feeAmount}
                </span>)
            }
        },
        {
            accessor: 'modeOfPayment',
            Header: 'Payment Mode',
        },
        {
            accessor: 'feePaymentDatetime',
            Header: 'Payment Date',
        },
        {
            accessor: '',
            Header: 'Invoice',
            Cell: ((e) => {
                const orderId = e?.row?.original?.orderId || ''
                return <DownloadIcon onClick={() => downloadReceiptViaOrderId(orderId)}/>
            })
        },
    ]

    const downloadReceiptViaOrderId = (orderId) => {
        downloadInvoiceReceipt(orderId, 'Invoice')
    }

    return (
        <>
            <div className='inner-content-wrap'>
                <div className='table-wrapper-outer super-admin-table-wrapper'>
                    <TableComponent
                        columns={columns}
                        data={rowsData}
                        showSelectedAll={false}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={setSelectedRows}
                        isPagination={rowsData.length > 0 ? true : false}
                        setActivePage={setActivePage}
                        totalRows={totalRows}
                        activePage={activePage}
                    />
                </div>
            </div>
        </>
    )
}

export default FeeTableApp;