import { Form, Formik } from "formik";
import OtpTimer from "otp-timer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePickerField from "../../components/form/DatePickerField";
import InputField from "../../components/form/InputField";
import { GENDER_OPTOPNS, GUARDIAN_OPTIONS, MEDICAL_CONDITIONS } from "../../constants/formContanst";
import { fastCheckOutValidationSchema } from "../../data/validationSchema";
import RestEndPoint from "../../redux/constants/RestEndpoints";
import RESTClient, { baseURL } from "../../utils/RestClient";
import { populateCities } from "../../utils/populateOptions";
import { requestOtp } from "../../utils/services";
import GenericDialog from "../GenericDialog";
import FastCheckOutOtpPage from "./fastCheckOutOtpPage";
import footerCkoutLogo from "../../assets/img/brand-logo-header.svg"
import logoCapLg from "../../assets/img/icons/logo-cap-lg.svg"
import logoCapWhite from "../../assets/img/icons/white-cap-lg.svg"
import dtlsSheet from "../../assets/img/icons/detailsSheet.svg"
import BlkWatck from "../../assets/img/icons/black-watch.svg"
import brndLogohdr from "../../assets/img/brand-logo-header.svg"
import Loader, { hideLoader, showLoader } from "../../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getDisabilites } from "../../redux/actions/masterData";
import { getLocalData, humanize } from "../../utils/helper";
import { Button } from "react-bootstrap";


const INITIAL_VALUES = {
    "firstName": "",
    "lastName": "",
    "email": "",
    "phone": "",
    "relationShip": "",
    "studentFirstName": "",
    "studentLastName": "",
    "dateOfBirth": "",
    "gender": "",
    "session": "",
    "applyingClass": "",
    "medicalCheckBox": "No",
    "medicalTextBox": "",
    "medicalCondition": "",
    "addressLine1": "",
    "addressLine2": "",
    "state": "",
    "city": "",
    "pincode": "",
    "selectedDisabilities": ""
}


const FastCheckout = ({ data, page, setPage }) => {

    const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
    const schoolId = data[0][1]
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({})
    const [schoolType, setSchoolType] = useState('')
    const [schoolDetails, setSchoolDetails] = useState('')
    const [timeLeft, setTimeLeft] = useState(5);
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [selectedSession, setSelectedSession] = useState('')
    const [noadmissionOpen, setNoAdmissionOpen] = useState(false)
    const [showFastCheckoutForm, setShowFastCheckoutForm] = useState(false)
    const [disabilitiesOption, setDisabilitiesOption] = useState([
        { text: "Select Disablity", value: "" },
    ])
    const [classesOption, setClassesOption] = useState([
        { text: "Select Class", value: "" },
    ]);
    const [stateOptions, setStateOptions] = useState([
        { text: "Select State", value: "" },
    ]);
    const [cityOptions, setCityOptions] = useState([
        { value: "", text: "Select City" }
    ])
    const [openSessionOption, setOpenSessionOption] = useState([
        { value: "", text: "Select Session" }
    ]);



    const handleClose = () => {
        setShowFastCheckoutForm(false)
        setPage(0)
    }

    const noadmissionOpenhandleClose = () => {
        setNoAdmissionOpen(false)
    }

    const loadDisablities = () => {
        RESTClient.get(RestEndPoint.GET_DISABILITIES)
            .then((response => {
                const data = response?.data?.disabilities.map(val => ({ text: humanize(val), value: val }))
                setDisabilitiesOption(disabilitiesOption.concat(data))
            }))
            .catch((error) => {
                console.log("Error while getting state list" + error);
            });
    }

    const populateStateList = () => {
        RESTClient.get(RestEndPoint.GET_STATE)
            .then((response) => {
                let states = [{ text: "Select State", value: "" }];
                if (response.data.success)
                    setStateOptions(
                        states.concat(
                            response.data.states.map((it) => ({
                                value: it.id,
                                text: it.name,
                            }))
                        )
                    );
            })
            .catch((error) => {
                console.log("Error while getting state list" + error);
            });
    };

    const loadSchoolDetails = () => {
        showLoader(dispatch)
        RESTClient.get(RestEndPoint.SCHOOL_BY_ID + "/" + schoolId)
            .then((response) => {
                setSchoolType(response?.data?.schoolType || "")
                setSchoolDetails(response?.data || "")
                const sessionList = response?.data?.admissionInfo
                    ? response?.data?.admissionInfo.map(v => v.admissionSession)
                    : []
                const uniqueArray = [...new Set(sessionList)].sort();
                const temp = uniqueArray.map((s) => {
                    let obj = {}
                    obj['value'] = s;
                    obj['text'] = s
                    return obj
                })
                if (uniqueArray.length === 0) {
                    setNoAdmissionOpen(true)
                } else {
                    setShowFastCheckoutForm(true)

                }
                setOpenSessionOption(oldArray => [...oldArray, ...temp])
                hideLoader(dispatch)
            })
            .catch((err) => {
                console.log(err)
                hideLoader(dispatch)
            })

    }

    const loadScoolClasses = (session) => {
        if (session) {
            const classList = schoolDetails.classes.filter((val) => (val.admissionSession === session && val.admissionStatus === "Admission Open"))
            const temp = classList.map((val) => {
                let obj = {}
                obj['value'] = val.classId;
                obj['text'] = val.className
                return obj
            })
            setClassesOption([...[{ text: "Select Class", value: "" }], ...temp]);
        }
    }

    const handleSubmitFastCheckOut = (data) => {
        setDisableSubmit(true)
        showLoader(dispatch)
        const payload = {
            phone: data.phone
        }
        requestOtp(payload)
            .then((res) => { setPage(1); setFormData(data); setDisableSubmit(false); hideLoader(dispatch) })
            .catch((err) => { console.log(err); setDisableSubmit(false); hideLoader(dispatch) })
    }



    useEffect(() => {
        if (page === 0 && Object.keys(formData).length > 0) {
            setInitialValues(formData)
        }
    }, [page])

    useEffect(() => {
        loadScoolClasses(selectedSession)
    }, [selectedSession])

    useEffect(() => {
        if (page === 2) {
            const timer = setInterval(() => {
                if (timeLeft === 0) {
                    clearInterval(timer);
                    const childId = getLocalData('childId')
                    navigate(`/admissionForm?childId=${btoa(`#${childId}`)}`)
                } else {
                    setTimeLeft(timeLeft - 1);
                }
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [timeLeft, page]);

    useEffect(() => {
        if (disabilitiesOption.length === 0) {
            dispatch(getDisabilites())
        }
    }, [disabilitiesOption])

    useEffect(() => {
        loadSchoolDetails();
        populateStateList();
        loadDisablities()
    }, [])


    return (
        <>
            <GenericDialog
                // className="signin-model "
                className={`signin-model ${page === 2 ? "timer-model" : ""
                    }`}
                show={showFastCheckoutForm}
                handleClose={handleClose}
                // modalHeader={page === 2 ? '': <strong>{`${schoolName} Admission Form`}</strong>}
                disableEscape
            >
                {page !== 2 &&
                    <div className="fstchkOut-hdr">
                        <div className="fstChkot-logo-container">
                            <img
                                // src={baseURL + schoolDetails?.schoolThumbnailImgLink}
                                src={logoCapWhite}
                                alt="fast-chekout-logo"
                                className="img-fluid d-none d-md-block"
                            />
                            <img
                                // src={baseURL + schoolDetails?.schoolThumbnailImgLink}
                                src={logoCapLg}
                                alt="fast-chekout-logo"
                                className="img-fluid d-block d-md-none"
                            />
                        </div>
                        <div className="Form-header-wrapper">
                            <h2 className="title-hdr">Admission Form</h2>
                            <span className="sub-title-hdr">
                                {schoolDetails?.schoolName}
                            </span>
                            <span className="dtls-lbl">
                                {schoolDetails?.addressLine1 + ', ' + schoolDetails?.addressLine2 + ', ' + schoolDetails?.city + ', ' + schoolDetails?.state}
                            </span>
                            <span className="dtls-lbl fs-14">
                                Phone : {schoolDetails?.contactNo}
                            </span>
                            <span className="dtls-lbl fs-14">
                                Email :{" "}
                                <span className="email-lbl-wrapp">
                                    {schoolDetails?.email}
                                </span>
                            </span>
                        </div>
                    </div>
                }
                {page === 0 && <Formik
                    initialValues={initialValues}
                    validationSchema={fastCheckOutValidationSchema}
                    enableReinitialize={true}
                    validateOnBlur
                    onSubmit={values => {
                        handleSubmitFastCheckOut(values)
                    }}
                >
                    {({ values, setFieldValue, errors, touched }) => (
                        <Form style={{ width: "100%" }}>
                            <div className="form-wrap">
                                <div className="row gx-3 info-container-wrapp">
                                    <span className="lbl-wrapp"><span>Parents/Guardian Details</span><span className="lbl-star">*</span></span>
                                    <div className="col-md-4 frm-cell">
                                        <label>
                                            First Name <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='firstName'
                                            value={values.firstName}
                                            className='frm-cell'
                                            fieldType='text'
                                            placeholder='First Name'
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className="col-md-4 frm-cell">
                                        <label>
                                            Last Name <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='lastName'
                                            value={values.lastName}
                                            className='frm-cell'
                                            fieldType='text'
                                            placeholder='Last Name'
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className="col-md-4 frm-cell">
                                        <label>
                                            Relationship <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='relationShip'
                                            value={values.relationShip}
                                            className='frm-cell'
                                            fieldType='select'
                                            placeholder=''
                                            selectOptions={GUARDIAN_OPTIONS}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className="col-md-4 frm-cell mb-3 mb-md-0">
                                        <label>
                                            Email <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='email'
                                            value={values.email}
                                            className='frm-cell'
                                            fieldType='text'
                                            placeholder='Email'
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className="col-md-4 frm-cell mb-3 mb-md-0">
                                        <label>
                                            Phone Number <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='phone'
                                            value={values.phone}
                                            className='frm-cell'
                                            fieldType='text'
                                            placeholder='Phone No.'
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                </div>
                                <div className="row gx-3 info-container-wrapp">
                                    <label className="lbl-wrapp"><span>Student Details</span><span className="lbl-star">*</span></label>
                                    <div className="col-md-4 frm-cell">
                                        <label>
                                            Session <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='session'
                                            value={values.session}
                                            className='frm-cell'
                                            fieldType='select'
                                            placeholder=''
                                            selectOptions={openSessionOption}
                                            errors={errors}
                                            touched={touched}
                                            onChange={(e) => {
                                                setSelectedSession(e.target.value)
                                                setFieldValue("session", e.target.value);
                                                setFieldValue("applyingClass", "");

                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4 frm-cell">
                                        <label>
                                            Applying to Class <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='applyingClass'
                                            value={values.applyingClass}
                                            className='frm-cell'
                                            fieldType='select'
                                            placeholder=''
                                            selectOptions={classesOption}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className="col-md-4 frm-cell">
                                        <label>
                                            First Name <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='studentFirstName'
                                            value={values.studentFirstName}
                                            className='frm-cell'
                                            fieldType='text'
                                            placeholder='Student First Name'
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className="col-md-4 frm-cell">
                                        <label>
                                            Last Name <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='studentLastName'
                                            value={values.studentLastName}
                                            className='frm-cell'
                                            fieldType='text'
                                            placeholder='Student Last Name'
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className="col-md-4 frm-cell">
                                        <label>
                                            Date Of Birth <span className="lbl-star">*</span>
                                        </label>
                                        <DatePickerField
                                            name='dateOfBirth'
                                            value={values.dateOfBirth}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            touched={touched}
                                            maxDate={new Date()}
                                        />
                                    </div>
                                    <div className="col-md-4 frm-cell">
                                        <label>
                                            Gender <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='gender'
                                            value={values.gender}
                                            className='frm-cell'
                                            fieldType='select'
                                            placeholder=''
                                            selectOptions={GENDER_OPTOPNS}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>

                                    <div className="col-md-4 frm-cell">
                                        <label style={{ marginBottom: "10px" }}>
                                            Any medical condition/allergies? <span className="lbl-star">*</span>
                                        </label>
                                        <div className="d-flex">
                                            <InputField
                                                fieldName='medicalCheckBox'
                                                value={'Yes'}
                                                label='Yes'
                                                checked={values.medicalCheckBox === 'Yes'}
                                                fieldType='radio'
                                                errors={errors}
                                                touched={touched}
                                                onClick={(e) => {
                                                    setFieldValue("medicalCondition", "");
                                                    setFieldValue("selectedDisabilities", "");
                                                    setFieldValue("medicalTextBox", "");
                                                }}
                                            />
                                            <InputField
                                                fieldName='medicalCheckBox'
                                                value={'No'}
                                                label='No'
                                                checked={values.medicalCheckBox === 'No'}
                                                fieldType='radio'
                                                errors={errors}
                                                touched={touched}
                                                onChange={(e) => {
                                                    setFieldValue("medicalCondition", "");
                                                    setFieldValue("selectedDisabilities", "");
                                                    setFieldValue("medicalTextBox", "");
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {values.medicalCheckBox === 'Yes' &&
                                        (<>
                                            <div className="col-md-4 frm-cell mb-0 ">
                                                <label>
                                                    Select Condition Type <span className="lbl-star">*</span>
                                                </label>
                                                <InputField
                                                    fieldName='medicalCondition'
                                                    value={values.medicalCondition}
                                                    className='frm-cell'
                                                    fieldType='select'
                                                    placeholder=''
                                                    selectOptions={MEDICAL_CONDITIONS}
                                                    errors={errors}
                                                    touched={touched}
                                                    onChange={(e) => {
                                                        setFieldValue("medicalCondition", e.target.value);
                                                        setFieldValue("selectedDisabilities", "");
                                                        setFieldValue("medicalTextBox", "");
                                                    }}
                                                />

                                            </div>
                                            {values.medicalCondition === 'disability' && <div className="col-md-4 frm-cell mb-0">
                                                <label>
                                                    Select Disability Type <span className="lbl-star">*</span>
                                                </label>
                                                <InputField
                                                    fieldName='selectedDisabilities'
                                                    value={values.selectedDisabilities}
                                                    className='frm-cell'
                                                    fieldType='select'
                                                    selectOptions={disabilitiesOption}
                                                    errors={errors}
                                                    touched={touched}
                                                    disabled={!(values.medicalCondition === 'disability')}
                                                    onChange={(e) => {
                                                        setFieldValue("selectedDisabilities", e.target.value);
                                                        setFieldValue("medicalTextBox", "");
                                                    }}
                                                />
                                            </div>}
                                            <div className="col-md-4 frm-cell mb-0">
                                                <label>
                                                    Comment : <span className="lbl-star">*</span>
                                                </label>
                                                <InputField
                                                    fieldName='medicalTextBox'
                                                    value={values.medicalTextBox}
                                                    className='frm-cell'
                                                    fieldType='textarea'
                                                    placeholder='Mention Details'
                                                    errors={errors}
                                                    touched={touched}
                                                    disabled={values.medicalCondition === '' || (values.medicalCondition === 'disability' && values.selectedDisabilities !== 'Other')}

                                                />
                                            </div>
                                        </>)}
                                </div>
                                <div className="row gx-3 info-container-wrapp">
                                    <label className="lbl-wrapp"><span>Address</span><span className="lbl-star">*</span></label>
                                    <div className="col-md-6 frm-cell">
                                        <label>
                                            Address Line 1 <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='addressLine1'
                                            value={values.addressLine1}
                                            className='frm-cell'
                                            fieldType='text'
                                            placeholder='Flat No. / Building No.'
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className="col-md-6 frm-cell">
                                        <label>
                                            Address Line 2 <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='addressLine2'
                                            value={values.addressLine2}
                                            className='frm-cell'
                                            fieldType='text'
                                            placeholder='Society Name'
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className="col-md-4 frm-cell mb-3 mb-md-0">
                                        <label>
                                            State <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='state'
                                            value={values.state}
                                            className='frm-cell'
                                            fieldType='select'
                                            placeholder=''
                                            selectOptions={stateOptions}
                                            onChange={(e) => {
                                                setFieldValue("state", e.target.value);
                                                setFieldValue("city", "Select City");
                                                populateCities(e.target.value, setCityOptions);
                                            }}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className="col-md-4 frm-cell mb-3 mb-md-0">
                                        <label>
                                            City <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='city'
                                            value={values.city}
                                            className='frm-cell'
                                            fieldType='select'
                                            placeholder=''
                                            selectOptions={cityOptions}
                                            onChange={(e) => {
                                                setFieldValue("city", e.target.value);
                                            }}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className="col-md-4 frm-cell mb-3 mb-md-0">
                                        <label>
                                            Pincode <span className="lbl-star">*</span>
                                        </label>
                                        <InputField
                                            fieldName='pincode'
                                            value={values.pincode}
                                            className='frm-cell'
                                            fieldType='text'
                                            placeholder='Pincode'
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="footer-btn-wrapp">
                                <div className="">
                                    <img src={footerCkoutLogo} alt="fast-chekout-logo" className="img-fluid" />
                                </div>
                                <div className="button-wrap">
                                    <button className='save-btn btn btn-primary' type="submit" disabled={disableSubmit}>Submit</button>
                                    <button className="btn cncl-btn" type="button" onClick={() => handleClose()} > Cancel</button>
                                </div>
                            </div>
                        </Form>
                    )}

                </Formik>}
                {page === 1 && <FastCheckOutOtpPage
                    formData={formData}
                    schoolId={schoolId}
                    admissionSession={selectedSession}
                    schoolType={schoolType}
                    handleClose={handleClose}
                    setPage={setPage}
                />}
                {page === 2 &&
                    <div className="timer-details-wrapp">
                        <div>
                            <img
                                src={dtlsSheet}
                                alt="fast-chekout-logo"
                                className="img-fluid"
                            />
                        </div>
                        <h5>Verifying form details!</h5>
                        <h6>School requested few more details for student verification to proceed further on the admission process.</h6>
                        <div className="timer-outer-wrapp">
                            <div className="timer-inner-wrapp">
                                <img
                                    src={BlkWatck}
                                    alt="fast-chekout-logo"
                                    className="img-fluid"
                                />
                                <label>
                                    <span>Please wait</span>
                                    <span>You will be shortly redirected to the details update form</span>
                                </label>
                            </div>
                            <div className="timer-container">
                                <b> 00:0{timeLeft}  </b> <span>seconds remaining</span>
                            </div>
                        </div>
                        <div className="w-100">
                            <img
                                src={brndLogohdr}
                                alt="fast-chekout-logo"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                }
            </GenericDialog>
            <Loader />
            <GenericDialog
                className='signin-model timer-model'
                show={noadmissionOpen}
                handleClose={noadmissionOpenhandleClose}
                disableEscape
            >
                <div className="fstchkOut-hdr">
                    <div className="fstChkot-logo-container">
                        <img
                            // src={baseURL + schoolDetails?.schoolThumbnailImgLink}
                            src={logoCapWhite}
                            alt="fast-chekout-logo"
                            className="img-fluid d-none d-md-block"
                        />
                        <img
                            // src={baseURL + schoolDetails?.schoolThumbnailImgLink}
                            src={logoCapLg}
                            alt="fast-chekout-logo"
                            className="img-fluid d-block d-md-none"
                        />
                    </div>
                    <div className="Form-header-wrapper">
                        <span className="sub-title-hdr">
                            {schoolDetails?.schoolName}
                        </span>
                        <span className="dtls-lbl">
                            {schoolDetails?.addressLine1 + ', ' + schoolDetails?.addressLine2 + ', ' + schoolDetails?.city + ', ' + schoolDetails?.state}
                        </span>
                        <span className="dtls-lbl fs-14">
                            Phone : {schoolDetails?.contactNo}
                        </span>
                        <span className="dtls-lbl fs-14">
                            Email :{" "}
                            <span className="email-lbl-wrapp">
                                {schoolDetails?.email}
                            </span>
                        </span>
                        <h2 className="title-hdr" style={{ marginTop: '15px' }}>Admission's Closed !! </h2>
                        <Button style={{ marginBottom: '15px', backgroundColor: 'grey', border: '1px solid grey' }} onClick={noadmissionOpenhandleClose}>
                            <span className="email-lbl-wrapp">
                                OK, Got It!
                            </span>
                        </Button>
                    </div>
                </div>
            </GenericDialog >
        </>
    )
}

export default FastCheckout;