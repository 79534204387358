import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import footerCkoutLogo from "../../assets/img/brand-logo-header.svg";
import stdntPic from "../../assets/img/icons/student-pic.svg";
import Loader, { hideLoader, showLoader } from "../../common/Loader";
import GenericDialog from "../../dialogs/GenericDialog";
import { getIpAddress, humanize } from "../../utils/helper";
import {
  processOrderAfterPayment,
  registerPayment,
} from "../../utils/services";

const staticFees = {
  lessThen: {
    platformFee: 20,
    gstFee: 3.6,
  },
  greaterThen: {
    platformFee: 50,
    gstFee: 9.0,
  },
};

const PayFee = ({
  show,
  handleClose,
  totalPay,
  setTotalPay,
  setPayFeeButton,
  data,
  submissionFrequency,
  first,
  studentData,
  monthQtr,
  setMonthQtr,
  session,
}) => {
  const [platformGstFee, setPlatformGstFee] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handlePayment = async () => {
    showLoader(dispatch);
    handleClose();
    setPayFeeButton(true);
    try {
      const ip = await getIpAddress();
      const payload = {
        childId: studentData.studentId,
        ipAddress: ip,
        deviceUserAgent: window.navigator.userAgent,
        feePaymentDto: {
          classId: studentData.classId,
          session: session,
          monthQtr: monthQtr,
        },
      };
      const response = await registerPayment(payload, session);
      navigate("/paymentCheckout", { state: { data: response.data } });
      setPayFeeButton(false);
      hideLoader(dispatch);
    } catch (error) {
      console.log("Error", error);
      setPayFeeButton(false);
      hideLoader(dispatch);
    }
  };

  const responseHandler = async (e, orderId) => {
    try {
      const formData = new FormData();
      formData.append("response", JSON.stringify(e));
      const response = await processOrderAfterPayment(formData, orderId);
      console.log(response);
      navigate(`/paymentFailed?${response.data}`);
    } catch (e) {
      console.log(e);
      toast.error("Payment Cancelled");
    }
  };

  const handleData = (monthQtr, platformGstFee) => {
    if (monthQtr) {
      const feeAmount = data.totalFeeDue || 0;
      const lateFee = data.lateFee || 0;
      const temp =
        feeAmount +
        lateFee +
        platformGstFee.platformFee +
        platformGstFee.gstFee;
      setTotalPay(parseFloat(temp));
    }
  };
  useEffect(() => {
    handleData(monthQtr, platformGstFee);
  }, [monthQtr, platformGstFee]);

  useEffect(() => {
    setMonthQtr(first);
  }, [first]);

  useEffect(() => {
    let lateFeeAmount = 0;
    if (data?.lateFee) {
      lateFeeAmount = lateFeeAmount + data.lateFee;
    }
    if (data?.totalFeeDue) {
      lateFeeAmount = lateFeeAmount + data.totalFeeDue;
    }
    if (lateFeeAmount > 0) {
      setPlatformGstFee(
        2000 < lateFeeAmount ? staticFees.greaterThen : staticFees.lessThen
      );
    } else {
      setPlatformGstFee({
        platformFee: 0,
        gstFee: 0,
      });
    }
  }, [data]);

  return (
    <GenericDialog
      show={show}
      handleClose={handleClose}
      modalHeader="Fee Payment"
      className="payfee-model add-child-model"
    >
      <Card>
        <Card.Body>
          {/* <FeeModalHeader student={studentData} session={session} /> */}
          <div className="pay-fee-student-tags">
            <div className="img-box-container">
              <img
                src={stdntPic}
                alt="fast-chekout-logo"
                className="img-fluid"
              />
            </div>
            <div class="tag-wrapper">
              <h2 className="title">
                {" "}
                {`${humanize(studentData.firstName || "")} ${humanize(
                  studentData.lastName || ""
                )} `}
              </h2>
              <span class="tag-item-lbl mb-n12">
                <label>
                  ID -{" "}
                  {studentData.schoolStudentId
                    ? studentData.schoolStudentId
                    : "NA"}
                </label>
              </span>
              <span className="tag-item-lbl-wrapp border-bottom-0">
                <span class="tag-item-lbl w-30n">
                  <label>Roll No. - </label>
                  <strong>
                    {studentData.rollNo ? studentData.rollNo : "NA"}
                  </strong>
                </span>
                <span class="tag-item-lbl w-70n">
                  <label>Academic Year - </label>
                  <strong>{session ? `(${session})` : "NA"}</strong>
                </span>
              </span>
              <span className="tag-item-lbl-wrapp">
                <span class="tag-item-lbl w-30n">
                  <label>Class - </label>
                  <strong>
                    {studentData.className ? studentData.className : "NA"}
                  </strong>
                </span>
                <span class="tag-item-lbl w-70n">
                  <label>Section - </label>
                  <strong>
                    {studentData.classSection ? studentData.classSection : "NA"}
                  </strong>
                </span>
              </span>
            </div>
          </div>
          <ListGroup>
            <ListGroup.Item className="session">
              <div className="list-item-row">
                <span className="list-cell gry-lht">
                  <label>Fee Period</label> &nbsp;
                </span>
                <span className="list-cell gry-drk">
                  <Form.Select
                    size="sm"
                    value={monthQtr}
                    onChange={(e) => setMonthQtr(e.target.value)}
                  >
                    <option value="">Select Fee Period</option>
                    {submissionFrequency.map((val, index) => (
                      <option key={index} value={val}>
                        {val}
                      </option>
                    ))}
                  </Form.Select>
                </span>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="list-cell gry-lht">Fee Amount</div>
              <div className="list-cell gry-drk">
                ₹{" "}
                {monthQtr
                  ? parseFloat(data.totalFeeDue).toFixed(2)
                  : parseFloat(0).toFixed(2)}
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="list-cell gry-lht">Late Fee</div>
              <div className="list-cell gry-drk">
                ₹{" "}
                {monthQtr
                  ? parseFloat(data.lateFee).toFixed(2)
                  : parseFloat(0).toFixed(2)}
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="list-cell gry-lht">Platform Fee</div>
              <div className="list-cell gry-drk">
                ₹{" "}
                {monthQtr
                  ? parseFloat(platformGstFee.platformFee).toFixed(2)
                  : parseFloat(0).toFixed(2)}
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="list-cell gry-lht">GST 18%</div>
              <div className="list-cell gry-drk">
                {" "}
                ₹{" "}
                {monthQtr
                  ? parseFloat(platformGstFee.gstFee).toFixed(2)
                  : parseFloat(0).toFixed(2)}
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div
                className="list-cell payment-cell"
                // style={{ marginRight: '10px', width: '150px' }}
              >
                Total Payment
              </div>
              <div className="list-cell amount-cell">
                ₹{" "}
                {monthQtr
                  ? parseFloat(totalPay).toFixed(2)
                  : parseFloat(0).toFixed(2)}
              </div>
            </ListGroup.Item>
          </ListGroup>

          <div className="btn-wrapper">
            <div className="">
              <img
                src={footerCkoutLogo}
                alt="fast-chekout-logo"
                className="img-fluid"
              />
            </div>
            <Button className="save-btn" onClick={handlePayment}>
              Proceed
            </Button>
          </div>
        </Card.Body>
      </Card>
      <Loader />
    </GenericDialog>
  );
};

export default PayFee;
