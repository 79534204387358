import { useEffect, useState } from "react";
import { Button, Form, ListGroup } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader, { hideLoader, showLoader } from "../../../../common/Loader";
import { MODE_OF_PAYMENT, SESSION } from "../../../../constants/app";
import GenericDialog from "../../../../dialogs/GenericDialog";
import { formatDateToDDMMYYYY } from "../../../../utils/DateUtil";
import { addOfflineFeeForStudent, getFeeAndPaymentHistoryForStudent } from "../../../../utils/services";
import FeeModalHeader from "./feeModalHeader";
import footerCkoutLogo from "../../../../assets/img/brand-logo-header.svg"
import { getLocalData } from "../../../../utils/helper";


export default function OfflineFeeModal({ show, handleClose, student, session }) {

    const [submissionFrequency, setSubmissionFrequency] = useState([])
    const [monthQtr, setMonthQtr] = useState('')
    const [lateFeeAmount, setLateFeeAmount] = useState('')
    const [totalFee, setTotalFee] = useState('')
    const [modeOfPayment, setModeOfPayment] = useState('')
    const [paymentDate, setPaymentDate] = useState('')
    const [errors, setErrors] = useState([]);

    const [data, setData] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [paidStatus, setPaidStatus] = useState(false)
    const dispatch = useDispatch()
    const maxDate = new Date()
    const currentSessionStartDate=getLocalData("sessionStartDate").split("/");
    const minDate = new Date(session.split("-")[0],currentSessionStartDate[1]-1,currentSessionStartDate[0])
    const errorVal = { monthQtr: "", lateFeeAmount: "", paymentDate: "", modeOfPayment: "" }

    const validateData = () => {
        if (monthQtr === '') {
            errorVal.monthQtr = "Required"
        }
        if (lateFeeAmount === '') {
            errorVal.lateFeeAmount = "Required"
        }
        if (paymentDate === '' || paymentDate === null) {
            errorVal.paymentDate = "Required"
        }
        if (modeOfPayment === '') {
            errorVal.modeOfPayment = "Required"
        }
        setErrors(errorVal)
    }

    const handleSubmit = () => {
        try {
            if (monthQtr === '' || lateFeeAmount === '' || lateFeeAmount < 0 || modeOfPayment === '' || paymentDate === '' || paymentDate === null) {
                if (lateFeeAmount < 0) {
                    errorVal.lateFeeAmount = "Value must be greater or equal to zero"
                    setErrors(errorVal)
                    toast.error("Late Fee must be greater or equal to zero")
                } else {
                    validateData()
                    toast.error("Required fields are empty")
                }
            } else {
                const payload = {
                    "studentId": student.studentId,
                    "classId": student.classId,
                    "monthQtr": monthQtr,
                    "session": session,
                    "lateFeeAmount": parseInt(lateFeeAmount),
                    "feeAmount": data[`${monthQtr}`].totalFeeDue,
                    "feePaymentDatetime": formatDateToDDMMYYYY(paymentDate),
                    "modeOfPayment": modeOfPayment,
                }
                addOfflineFeeForStudent(payload)
                    .then(res => {
                        setRefresh(val => !val)
                        handleClose()
                        toast.success("Payment is Successfully Updated  ")

                    })
                    .catch(err => toast.error(err.response.data.apierror.message))

            }
        }
        catch (e) {
            toast.error(e.response.data.apierror.message)
        }

    }

    const fetchFeeAndPaymentHistoryForStudent = () => {
        showLoader(dispatch)
        getFeeAndPaymentHistoryForStudent(session, student.classId, student.studentId)
            .then(response => {
                if (response.status === 200) {
                    const temp = response?.data
                    setData(temp)
                    const allKeys = Object.keys(temp)
                    const period = allKeys.filter((val) => (temp[`${val}`].feeStatus) !== 'Paid')
                    if (period.length) {
                        setSubmissionFrequency(period)
                        setPaidStatus(false)
                    } else {
                        setPaidStatus(true)
                        setSubmissionFrequency([])
                    }
                    hideLoader(dispatch)
                }
            })
            .catch(error => {
                console.log(error);
                toast.error(error.response.data.apierror.message || "Something went wrong!")
                setSubmissionFrequency([])
                hideLoader(dispatch)
            });
    }

    useEffect(() => {
        fetchFeeAndPaymentHistoryForStudent()
    }, [refresh])


    return (
        <GenericDialog
            show={show}
            handleClose={handleClose}
            modalHeader="Offline Payment"
            className="offline-payment-model"
        >
            <FeeModalHeader student={student} session={session} />
            <Loader />
            <div className="offline-paymt-frm-wrapper">
                {paidStatus && <div>
                    <span
                        style={{
                            color: 'red',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        All Fee's are Paid
                    </span>
                </div>}
                <ListGroup>
                        <ListGroup.Item className="session">
                            <div className="list-item-row">
                                <span className="list-cell gry-lht"><label>Fee Period</label> &nbsp;</span>
                                <span className="list-cell gry-drk">
                                <Form.Select
                            size='sm'
                            value={monthQtr}
                            disabled={paidStatus}
                            style={{
                                borderColor: `${errors.monthQtr ? 'red' : ''}`
                            }}
                            onChange={(e) => {
                                setMonthQtr(e.target.value);
                                setLateFeeAmount(data[`${e.target.value}`].lateFee || 0)
                                setTotalFee(data[`${e.target.value}`].totalFeeDue + data[`${e.target.value}`].lateFee)
                                setErrors(val => {
                                    return {
                                        ...val,
                                        monthQtr: undefined,
                                        lateFeeAmount: undefined,
                                    }
                                })
                            }}
                        >
                            <option value=''>Select Fee Period</option>
                            {submissionFrequency.map((val, index) => <option key={index} value={val}>{val}</option>)
                            }
                        </Form.Select>
                                </span>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <div className="list-cell gry-lht">Late Fees</div>
                            <div className="list-cell gry-drk">
                                <Form.Control
                            as={"input"}
                            size='sm'
                            type="number"
                            value={lateFeeAmount}
                            min={0}
                            placeholder={"Enter Late Fee"}
                            disabled={paidStatus || (monthQtr === '')}
                            style={{
                                backgroundColor: 'white',
                                borderColor: `${errors.lateFeeAmount ? 'red' : ''}`
                            }}
                            onChange={(e) => {
                                if(e.target.value >= 0) {
                                    setLateFeeAmount(e.target.value);
                                    setTotalFee(data[`${monthQtr}`].totalFeeDue+ parseFloat(e.target.value) || 0)
                                    setErrors(val => {
                                        return {
                                            ...val,
                                            lateFeeAmount: undefined,
                                        }
                                    })
                                }
                            }}
                        /></div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <div className="list-cell gry-lht">Payment Mode</div>
                            <div className="list-cell gry-drk">
                            <Form.Select
                            size='sm'
                            value={modeOfPayment}
                            style={{ borderColor: `${errors.modeOfPayment ? 'red' : ''}` }}
                            disabled={paidStatus}
                            onChange={(e) => {
                                setModeOfPayment(e.target.value);
                                setErrors(val => {
                                    return {
                                        ...val,
                                        modeOfPayment: undefined,
                                    }
                                })
                            }}
                        >
                            <option value=''>Select Payment Mode</option>
                            {MODE_OF_PAYMENT.map((val, index) => <option key={index} value={val.value}>{val.text}</option>)
                            }
                        </Form.Select>                                
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <div className="list-cell gry-lht">Amount</div>
                            <div className="list-cell gry-drk">
                            <Form.Control
                            size='sm'
                            value={monthQtr ? data[`${monthQtr}`].totalFeeDue : ''}
                            placeholder='Amount'
                            disabled={true}
                            style={{ color: 'blue', backgroundColor: 'white' }}
                        />
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <div className="list-cell gry-lht">Payment Date</div>
                            <div className="list-cell gry-drk"
                            // style={{ border: '1px solid #ced4da', fontSize: '14px', borderRadius: '5px', padding: '2px 0px 2px 10px', borderColor: `${errors.paymentDate ? 'red' : '#ced4da'}` }}
                        >
                            <div className="datepicker-fld">
                            <ReactDatePicker
                                selected={paymentDate}
                                disabled={paidStatus}
                                onChange={(date) => {
                                    setPaymentDate(date);
                                    setErrors(val => {
                                        return {
                                            ...val,
                                            paymentDate: undefined,
                                        }
                                    })
                                }}
                                minDate={minDate}
                                maxDate={maxDate}
                                placeholderText="DD/MM/YYYY"
                                dateFormat='dd/MM/yyyy'
                                showIcon
                            />
                            {/* <i className="icons calender-icon"></i> */}
                            </div>

                        </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <div className="list-cell payment-cell"
                            >Total Fee</div>
                            <div className="list-cell amount-cell">
                             <Form.Control
                            size='sm'
                            value={monthQtr ? totalFee : ''}
                            placeholder='Total Amount'
                            disabled={true}
                            style={{ color: 'blue', backgroundColor: 'white' }}
                        />
                            </div>
                        </ListGroup.Item>
                    </ListGroup>   


                {/* <div className="frm-row" >
                    <div className="cell-item">
                        <label>Fee Period</label>
                        <Form.Select
                            size='sm'
                            value={monthQtr}
                            disabled={paidStatus}
                            style={{
                                borderColor: `${errors.monthQtr ? 'red' : ''}`
                            }}
                            onChange={(e) => {
                                setMonthQtr(e.target.value);
                                setLateFeeAmount(data[`${e.target.value}`].lateFee || 0)
                                setTotalFee(data[`${e.target.value}`].totalFeeDue + data[`${e.target.value}`].lateFee)
                                setErrors(val => {
                                    return {
                                        ...val,
                                        monthQtr: undefined,
                                        lateFeeAmount: undefined,
                                    }
                                })
                            }}
                        >
                            <option value=''>Select Fee Period</option>
                            {submissionFrequency.map((val, index) => <option key={index} value={val}>{val}</option>)
                            }
                        </Form.Select>
                    </div>

                    <div className="cell-item">
                        <label>Late Fees</label>
                        <Form.Control
                            as={"input"}
                            size='sm'
                            type="number"
                            value={lateFeeAmount}
                            min={0}
                            placeholder={monthQtr === '' ? "Select Fee Period" : "Enter Late Fee"}
                            disabled={paidStatus || (monthQtr === '')}
                            style={{
                                backgroundColor: 'white',
                                borderColor: `${errors.lateFeeAmount ? 'red' : ''}`
                            }}
                            onChange={(e) => {
                                if(e.target.value >= 0) {
                                    setLateFeeAmount(e.target.value);
                                    setTotalFee(data[`${monthQtr}`].totalFeeDue+ parseFloat(e.target.value) || 0)
                                    setErrors(val => {
                                        return {
                                            ...val,
                                            lateFeeAmount: undefined,
                                        }
                                    })
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="frm-row" >
                    <div className="cell-item">
                        <label>Payment Mode</label>
                        <Form.Select
                            size='sm'
                            value={modeOfPayment}
                            style={{ borderColor: `${errors.modeOfPayment ? 'red' : ''}` }}
                            disabled={paidStatus}
                            onChange={(e) => {
                                setModeOfPayment(e.target.value);
                                setErrors(val => {
                                    return {
                                        ...val,
                                        modeOfPayment: undefined,
                                    }
                                })
                            }}
                        >
                            <option value=''>Select Payment Mode</option>
                            {MODE_OF_PAYMENT.map((val, index) => <option key={index} value={val.value}>{val.text}</option>)
                            }
                        </Form.Select>
                    </div>
                    <div className="cell-item">
                        <label>Amount</label>
                        <Form.Control
                            size='sm'
                            value={monthQtr ? data[`${monthQtr}`].totalFeeDue : ''}
                            placeholder='Fee Amount'
                            disabled={true}
                            style={{ color: 'blue', backgroundColor: 'white' }}
                        />
                    </div>
                </div>
                <div className="frm-row" >
                    <div className="cell-item">
                        <label>Payment Date</label>
                        <div className="datepicker-fld"
                            style={{ border: '1px solid #ced4da', fontSize: '14px', borderRadius: '5px', padding: '2px 0px 2px 10px', borderColor: `${errors.paymentDate ? 'red' : '#ced4da'}` }}
                        >
                            <ReactDatePicker
                                selected={paymentDate}
                                disabled={paidStatus}
                                onChange={(date) => {
                                    setPaymentDate(date);
                                    setErrors(val => {
                                        return {
                                            ...val,
                                            paymentDate: undefined,
                                        }
                                    })
                                }}
                                minDate={minDate}
                                maxDate={maxDate}
                                placeholderText="DD/MM/YYYY"
                                dateFormat='dd/MM/yyyy'
                                showIcon
                            />
                        </div>
                    </div>
                    <div className="cell-item">
                        <label>Total Fee</label>
                        <Form.Control
                            size='sm'
                            value={monthQtr ? totalFee : ''}
                            placeholder='Total Amount'
                            disabled={true}
                            style={{ color: 'blue', backgroundColor: 'white' }}
                        />
                    </div>
                </div> */}
                <div className="btn-wrapper">
                <div className="">
                    <img src={footerCkoutLogo} alt="fast-chekout-logo" className="img-fluid"/>
                   </div> 
                    <Button className="submit-btn" disabled={paidStatus} onClick={handleSubmit}>Submit</Button>
                </div>
            </div>
        </GenericDialog>
    )
}
