import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import Layout from "../common/layout";
import "../assets/scss/custom-styles.scss";
import Breadcrumbs from "../common/Breadcrumbs";
import { formatDateToDDMMYYYY } from "../utils/DateUtil";
import { useEffect } from "react";
import { convertQueryStringIntoObject } from "../utils/helper";


const OrderConfirm = () => {
  const navigate = useNavigate()
  const search = useLocation().search;
  const param = new URLSearchParams(search).get("param");
  const params = convertQueryStringIntoObject(param, true)

  return (
    <Layout>
      <section className="content-area about-page">
        <Container className="content-area-inner inner-page-container">
          <Row className="content-section bc-section">
            <Col className="bc-col">
              <Breadcrumbs />
            </Col>
          </Row>
          <Row className="content-section about-content-main mt-n20">
            <section className="contact-section-wrapper pd-md-0n">
              <div className="boxed-content-area payment-failed received-payment">
                <i className="pymtReceived-icon"></i>
                <h2>Congratulations!</h2>
                {params?.orderType === "ADMISSION_FORM" || params?.orderType === "REGISTRATION_FEE"? (
                  <h4>
                    Your payment has been received for 
                    {params?.orderType === "ADMISSION_FORM" ? <> {params?.applications ? `Application No. ${params?.applications}` : 'Application'}</> : <>{params?.applications}</>}. You can go to your dashboard and select
                    the student to track application status. You can also
                    download invoice and money receipt from Payment History tab.
                  </h4>
                ) : (
                  <h4>
                    Your fee payment has been received for {params?.applications || ""}.
                    You may view and download invoice and money receipt from
                    Dashboard > Manage Fee > Student> Payment History.
                  </h4>
                )}
                <div className="status-contaier-wrapp">
                  <div className="payment-details-wrapp">
                    <h6 className="payment-details-title">Transaction Details</h6>
                    {/* <div className="flex-btwen-wrapp"> */}
                    <div className="pymt-wrapp pt-0">
                      <div className="payment-flc-btwn trns-lbl-wrapp"><label style={{ width: '20%' }}>Transaction No.</label><span>{params?.txnId || ""}</span></div>
                    </div>
                    <div className="pymt-wrapp">
                      <div className="payment-flc-btwn"><label>Payment Date</label><span>{formatDateToDDMMYYYY(params?.txnDate) || ""} </span></div>
                      <div className="payment-flc-btwn"><label>Payment Mode</label><span>{params?.paymentMode || ""}</span></div>
                    </div>
                    <div className="pymt-wrapp">
                      <div className="payment-flc-btwn"><label>Paid Amount</label><span>{params?.txnAmount || ""}</span></div>
                      <div className="payment-flc-btwn"><label>Status </label><span className="status-lbl-successs">Success</span></div>
                    </div>
                    {/* </div> */}
                  </div>
                  <label className="viewDtls-lbl">
                    You can view the details on the
                    <a href={"/paymentHistory"}>
                      <span>
                        &nbsp;Payment History&nbsp;
                      </span>
                    </a>Page</label>
                  <Button className="status-btn" onClick={() => navigate('/paymentHistory')} >OK, Got It!</Button>
                </div>
              </div>
            </section>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default OrderConfirm;
