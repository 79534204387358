
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Layout from "../../admin/layout";
import { humanize } from "../../../utils/helper";
import { getParentUsers } from "../../../utils/services";
import TableComponent from "../../../common/TableComponent";
import Loader, { hideLoader, showLoader } from "../../../common/Loader";
import StringUtils from "../../../utils/StringUtils";



const ParentUser = () => {

    const [rowsData, setRowsData] = useState([])
    const [selectedRows, setSelectedRows] = useState({});
    const dispatch = useDispatch()
    const columns = [
        {
            accessor: '',
            Header: 'S. No.',
            Cell: ((e) => {
                console.log(e.row.original)
                return (
                    <div>
                        <span>{e.row.index + 1}</span>
                    </div>
                );
            })
        },
        {
            accessor: 'userId',
            Header: 'User ID',
        },
        {
            accessor: '',
            Header: 'Full Name',
            Cell: ((e) => {
                return (
                    <div>
                        <span>{humanize(e.row.original?.firstName)} {humanize(e.row.original?.lastName)}</span>
                    </div>
                );
            })
        },
        {
            accessor: 'email',
            Header: 'Email ID',
        },
        {
            accessor: 'phone',
            Header: 'Phone Number',
        },
        {
            accessor: '',
            Header: 'Verified User',
            Cell: ((e) => {
                return (
                    <div>
                        <span style={{ color: '#41285F' }}>{e.row.original?.verify ? 'Yes' : 'No'}</span>
                    </div>
                );
            })
        },
        {
            accessor: '',
            Header: 'Address',
            Cell: ((e) => {
                const getPresentableAddress = (val) => {
                    let address = "";
                    if (val?.addressLine1)
                        address = StringUtils.append(address, val?.addressLine1, ",");
                    if (val?.addressLine2)
                        address = StringUtils.append(address, val?.addressLine2, ",");
                    if (val?.city)
                        address = StringUtils.append(address, val?.city, ",");
                    if (val?.state)
                        address = StringUtils.append(address, val?.state, ",");
                    if (val?.pincode)
                        address = StringUtils.append(address, val?.pincode, "-");
                    return address || 'NA';
                }
                return (
                    <div>
                        <span style={{ color: '#41285F' }}>{getPresentableAddress(e.row.original)}</span>
                    </div>
                );
            })
        },
    ]


    const loadParentUserDetail = () => {
        showLoader(dispatch)
        getParentUsers()
            .then((res) => {
                setRowsData(res.data)
                hideLoader(dispatch)
            })
            .catch(err => {
                console.log(err);
                hideLoader(dispatch)
            })
    }


    useEffect(() => {
        loadParentUserDetail()
    }, [])


    return (
        <Layout>
            <Loader />
            <TableComponent
                columns={columns}
                data={rowsData}
                showSelectedAll={false}
                selectedRows={selectedRows}
                onSelectedRowsChange={setSelectedRows}
            // isPagination={true}
            // setActivePage={setActivePage}
            // totalRows={totalRows}
            // activePage={activePage}
            />
        </Layout>
    )
}


export default ParentUser;