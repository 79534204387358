import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import GenericDialog from "../../../../dialogs/GenericDialog";
import { humanize } from "../../../../utils/helper";
import { addFeeInStudenFee, getClassesFeeDetails, removeFeeFromStudenFee } from "../../../../utils/services";
import FeeModalHeader from "./feeModalHeader";
import Loader, { hideLoader, showLoader } from "../../../../common/Loader";
import { useDispatch } from "react-redux";
import footerCkoutLogo from "../../../../assets/img/brand-logo-header.svg"

export default function ConfigureFeeModal({ configureFeeModal, handleClose, student, fetchStudentFees, feesDetail, session }) {
  const [calculatedFee, setCalculatedFees] = useState(0);
  const [classFees, setClassFees] = useState([]);
  const dispatch = useDispatch()

  const fetchStudentFeesData = (feesDetail) => {
    showLoader(dispatch)
    getClassesFeeDetails(student.classId, session)
      .then(response => {
        if (response.status === 200) {
          const result = response?.data.map(val => {
            const data = feesDetail.find(fee => fee.studentFee.feeTypeId === val.classFee.feeTypeId);
            return {
              ...val,
              disabled: val?.classFee?.mandatory,
              isChecked: Boolean(data),
              mandatoryFees: data,
            };
          });
          setClassFees(result);
        }
        hideLoader(dispatch)
      })
      .catch(error => {
        console.log(error);
        hideLoader(dispatch)
      });
  };

  const getCalulagedFee = (fees) => {
    let temp = 0;
    if (fees.length > 0) {
      fees.map(val => {
        if (val?.classFee && val?.isChecked) {
          temp = temp + val?.classFee?.feeAmount;
        }
      });
    }
    return temp;
  }

  const handleInput = (isChecked, index, fees) => {
    showLoader(dispatch)
    const studentId = student.studentId;
    if (isChecked) {
      const payload = {
        "studentId": studentId,
        "classId": fees.classId,
        "classFeeId": fees.classFeeId,
      }
      addFeeInStudenFee(payload)
        .then(res => {
          fetchStudentFees()
        })
        .catch(err => {
          console.log(err)
          hideLoader(dispatch)

        });
    } else if (fees?.mandatoryFees?.studentFeeId) {
      const studentFeeId = fees?.mandatoryFees?.studentFeeId;
      if (studentId && studentFeeId) {
        removeFeeFromStudenFee(studentId, studentFeeId)
          .then(res => {
            fetchStudentFees()
          })
          .catch(err => {
            console.log(err)
            hideLoader(dispatch)
          });
      }
    } else {
      if (studentId) {
        toast.error("Student Fee Id is missing")
      } else {
        toast.error("Student Id is missing")
      }
      hideLoader(dispatch)
    }
  }

  useEffect(() => {
    if (classFees.length > 0) {
      setCalculatedFees(getCalulagedFee(classFees));
    }
  }, [classFees]);

  useEffect(() => {
    fetchStudentFeesData(feesDetail);
  }, [feesDetail]);

  return (
    <GenericDialog
      show={configureFeeModal}
      handleClose={handleClose}
      modalHeader='Configure fee'
      className="Student-fee-model Configure-fee-wrapp"
    >
      <div className="px-n30">
      <FeeModalHeader student={student} session={session} />
      <Loader />
      <div className="table-wrapper">
        <table className="table" style={{ width: '100%', marginBottom: "0px" }}>
          <thead>
            <tr valign="middle">
              <th>Fee Type</th>
              <th>Frequency</th>
              <th>Fee Amount</th>
              <th style={{ textAlign: "center" }}>Mandatory</th>
            </tr>
          </thead>
          <tbody>
            {classFees && classFees.length > 0 &&
              classFees.map((val, index) => (
                <tr valign="middle" key={`configureFee${index}`} >
                  <td>{val?.classFee?.feeTypeName}</td>
                  <td>{humanize(val?.classFee?.feeTypeFrequency)}</td>
                  <td>{val?.classFee?.feeAmount}</td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      disabled={val?.disabled}
                      checked={val?.isChecked || false}
                      onChange={e => {
                        handleInput(e.target.checked, index, val);
                      }}
                    />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      </div>
      <div className="total-amount-wrapp">
        <div className="">
          <img src={footerCkoutLogo} alt="fast-chekout-logo" className="img-fluid"/>
        </div> 
        <span className="amount-lbl">Total Amount Due <span> ₹{calculatedFee}/-</span></span>
      </div>
    </GenericDialog>
  );
}