import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import InputField from "../../components/form/InputField";
import StringUtils from "../../utils/StringUtils";
import RESTClient from "../../utils/RestClient";
import RestEndPoint from "../../redux/constants/RestEndpoints";
import { populateCities } from "../../utils/populateOptions";
import { humanize } from "../../utils/helper";
import { GUARDIAN_OPTIONS } from "../../constants/formContanst";
import Loader, { hideLoader, showLoader } from "../../common/Loader";
import { useDispatch } from "react-redux";
import { fastChekcoutParentUpdateUser } from "../../utils/services";


const FastChekOutGuradianDetail = ({ fastCheckOutData, detail, fetchChildAndParentDetails, setEnableDeclarationChecks }) => {

    const [edit, setEdit] = useState(false)
    const [stateOptions, setStateOptions] = useState([{ text: "Select State", value: "" }]);
    const [cityOptions, setCityOptions] = useState([{ value: "", text: "Select City" }])
    const dispatch = useDispatch();

    const initialValue = {
        childId: detail.childId,
        firstName: fastCheckOutData?.firstName,
        lastName: fastCheckOutData?.lastName,
        email: fastCheckOutData?.email,
        phone: fastCheckOutData?.phone,
        relationShip: detail.relationShip,
        addressLine1: fastCheckOutData?.addressLine1,
        addressLine2: fastCheckOutData?.addressLine2,
        city: fastCheckOutData?.cityId,
        state: fastCheckOutData?.stateId,
        pincode: fastCheckOutData?.pincode
    }

    const getPresentableAddress = (val) => {
        let address = "";
        if (val?.addressLine1)
            address = StringUtils.append(address, val?.addressLine1, ",");
        if (val?.addressLine2)
            address = StringUtils.append(address, val?.addressLine2, ",");
        if (val?.city)
            address = StringUtils.append(address, val?.city, ",");
        if (val?.state)
            address = StringUtils.append(address, val?.state, ",");
        if (val?.pincode)
            address = StringUtils.append(address, val?.pincode, "-");
        return address;
    }

    const populateStateList = () => {
        RESTClient.get(RestEndPoint.GET_STATE)
            .then((response) => {
                let states = [{ text: "Select State", value: "" }];
                if (response.data.success)
                    setStateOptions(
                        states.concat(
                            response.data.states.map((it) => ({
                                value: it.id,
                                text: it.name,
                            }))
                        )
                    );
                if (edit) {
                    populateCities(initialValue.state, setCityOptions)
                }
            })
            .catch((error) => {
                console.log("Error while getting state list" + error);
            });

    };

    const handleSubmit = (data) => {
        showLoader(dispatch)
        fastChekcoutParentUpdateUser(data)
            .then(res => {
                fetchChildAndParentDetails(data.childId);
                setEdit(false)
                hideLoader(dispatch)
            })
            .catch(err => { hideLoader(dispatch); console.log(err) })
    }

    useEffect(() => {
        populateStateList()
        if (edit) {
            setEnableDeclarationChecks(true)
        } else {
            setEnableDeclarationChecks(false)
        }
    }, [edit])

    return (
        <>
            <Accordion.Header>Parents/Guardian</Accordion.Header>
            <Accordion.Body>
                <Formik
                    initialValues={initialValue}
                    onSubmit={values => handleSubmit(values)}
                    enableReinitialize
                >
                    {({ values, setFieldValue, errors, touched, resetForm }) => (
                        <Form className="row g-3">
                            <div className="admin-detail-row">
                                <div className="admin-detail-cell">
                                    <label>Name:</label>
                                    <span className="item-entry" style={{ display: 'flex' }}>
                                        {!edit
                                            ? initialValue?.firstName
                                            : <InputField
                                                fieldName='firstName'
                                                value={values.firstName}
                                                className='frm-cell'
                                                fieldType='text'
                                                placeholder='First Name'
                                                required
                                                errors={errors}
                                                touched={touched}
                                            />}
                                        {!edit ? " " : <div style={{ margin: ' 0 10px' }}></div>}
                                        {!edit
                                            ? initialValue?.lastName
                                            : <InputField
                                                fieldName='lastName'
                                                value={values.lastName}
                                                className='frm-cell'
                                                fieldType='text'
                                                placeholder='Last Name'
                                                required
                                                errors={errors}
                                                touched={touched}
                                            />}
                                    </span>
                                </div>
                                {/* <div className="admin-detail-cell" ></div> */}
                                {false && <div className="admin-detail-cell" style={{ alignItems: 'flex-end' }}>
                                    {!edit ?
                                        <Button onClick={() => setEdit(true)}>Edit</Button> :
                                        <div style={{ display: 'flex', }}>
                                            <Button type="submit" >SAVE</Button>
                                            <div style={{ margin: '0 5px' }}></div>
                                            <Button type="reset" onClick={() => { resetForm(); setEdit(false) }}>Reset</Button>
                                        </div>
                                    }
                                </div>}
                                <div className="admin-detail-cell">
                                    <label>Email:</label>
                                    <span className="item-entry eml-lbl" >
                                        {!edit
                                            ? initialValue?.email
                                            : <InputField
                                                fieldName='email'
                                                value={values.email}
                                                className='frm-cell'
                                                fieldType='text'
                                                placeholder='Email'
                                                required
                                                errors={errors}
                                                touched={touched}
                                            />}
                                    </span>
                                </div>
                                <div className="admin-detail-cell">
                                    <span>Relation:</span>
                                    <span className="item-entry" >
                                        {!edit
                                            ? humanize(initialValue?.relationShip)
                                            : <InputField
                                                fieldName='relationShip'
                                                value={values.relationShip}
                                                className='frm-cell'
                                                fieldType='select'
                                                placeholder='Relation Ship'
                                                selectOptions={GUARDIAN_OPTIONS}
                                                errors={errors}
                                                touched={touched}
                                                required
                                            />}
                                    </span>
                                </div>
                                {/* <div className="admin-detail-cell" ></div> */}

                                {!edit
                                    ?
                                    <div className="admin-detail-cell">
                                        <label>Address:</label>
                                        <span className="item-entry adrs-lbl">
                                            {getPresentableAddress(fastCheckOutData)}
                                        </span>
                                    </div>
                                    : <>
                                        <div className="admin-detail-cell">
                                            <label>Address:</label>
                                        </div>
                                        <div className="admin-detail-cell"></div>
                                        <div className="admin-detail-cell"></div>
                                        <div className="admin-detail-cell">
                                            <span style={{ width: '100%' }}>
                                                <InputField
                                                    fieldName='addressLine1'
                                                    value={values.addressLine1}
                                                    className='frm-cell'
                                                    fieldType='text'
                                                    placeholder='Address Line 1'
                                                    required
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </span>
                                        </div>
                                        <div className="admin-detail-cell">
                                            <span style={{ width: '100%' }}>
                                                <InputField
                                                    fieldName='addressLine2'
                                                    value={values.addressLine2}
                                                    className='frm-cell'
                                                    fieldType='text'
                                                    placeholder='Address Line 2'
                                                    required
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </span>
                                        </div>
                                        <div className="admin-detail-cell">
                                            <span style={{ width: '100%' }}>
                                                <InputField
                                                    fieldName='state'
                                                    value={values.state}
                                                    className='frm-cell'
                                                    fieldType='select'
                                                    placeholder='Select State'
                                                    selectOptions={stateOptions}
                                                    onChange={(e) => {
                                                        setFieldValue("state", e.target.value);
                                                        setFieldValue("city", "Select City");
                                                        populateCities(e.target.value, setCityOptions);
                                                    }}
                                                    errors={errors}
                                                    touched={touched}
                                                    required
                                                />
                                            </span>
                                        </div>
                                        <div className="admin-detail-cell">
                                            <span style={{ width: '100%' }}>
                                                <InputField
                                                    fieldName='city'
                                                    value={values.city}
                                                    className='frm-cell'
                                                    fieldType='select'
                                                    placeholder='Select State'
                                                    selectOptions={cityOptions}
                                                    onChange={(e) => {
                                                        setFieldValue("city", e.target.value);
                                                    }}
                                                    errors={errors}
                                                    touched={touched}
                                                    required
                                                />
                                            </span>
                                        </div>
                                        <div className="admin-detail-cell">
                                            <span style={{ width: '100%' }}>
                                                <InputField
                                                    fieldName='pincode'
                                                    value={values.pincode}
                                                    className='frm-cell'
                                                    fieldType='text'
                                                    placeholder='Pincode'
                                                    errors={errors}
                                                    touched={touched}
                                                    required
                                                />
                                            </span>
                                        </div>
                                    </>}
                            </div>
                        </Form>)}
                </Formik>
            </Accordion.Body>
            <Loader/>
        </>
    )
}


export default FastChekOutGuradianDetail;