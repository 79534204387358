
import { useState } from 'react';
import { Button, Form } from "react-bootstrap";
import OtpInput from 'react-otp-input';
import OtpTimer from "otp-timer";
import { confirmFastCheckOut, requestOtp } from '../../utils/services';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { isLoggedIn, setLocalData, setUserLoginData } from '../../utils/helper';
import { useDispatch } from 'react-redux';
import { setIsUserLoggedIn } from '../../redux/actions/userAction';


const FastCheckOutOtpPage = ({
    formData,
    schoolId,
    admissionSession,
    schoolType,
    handleClose,
    setPage,
}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [otp, setOtp] = useState("");
    const [resendOtp, setResendOtp] = useState(false);
    const [disableVerifyButton, setDisableVerifyButton] = useState(false)

    const handleOtpChange = (otps) => {
        setOtp(otps);
    };

    const handleOtpSubmit = () => {
        setDisableVerifyButton(true)
        let medCond = ''
        if (formData.selectedDisabilities) {
            medCond = `${formData.medicalCondition}:${formData.selectedDisabilities}${formData.medicalTextBox ? `:${formData.medicalTextBox}` : ""}`

        } else if(formData.medicalCondition) {
            medCond = `${formData.medicalCondition}:${formData.medicalTextBox}`
        }
        const payload = {
            "firstName": formData.firstName,
            "lastName": formData.lastName,
            "otp": otp,
            "email": formData.email,
            "phone": formData.phone,
            "addressLine1": formData.addressLine1,
            "addressLine2": formData.addressLine2,
            "city": formData.city,
            "state": formData.state,
            "schoolId": schoolId,
            "pincode": formData.pincode,
            "childDto": {
                "firstName": formData.studentFirstName,
                "lastName": formData.studentLastName,
                "dateOfBirth": formData.dateOfBirth,
                "gender": formData.gender,
                "classId": formData.applyingClass,
                "medicalCondition": medCond,
                "relationShip": formData.relationShip,
                "admissionSession": admissionSession
            }
        }
        confirmFastCheckOut(payload)
            .then((res) => {
                const SchoolDetailsLatitude = localStorage.getItem('SchoolDetailsLatitude');
                const SchoolDetailsLongitude = localStorage.getItem('SchoolDetailsLongitude');
                const cities = localStorage.getItem('cities');
                const childId = res.data.parentData.childId;
                toast.success('done');
                setLocalData('fastCheckOut', res.data.parentData.fastCheckout);
                setLocalData('childId', childId);
                setUserLoginData(res.data, SchoolDetailsLatitude, SchoolDetailsLongitude, cities);
                dispatch(setIsUserLoggedIn(isLoggedIn()));
                if (schoolType === "Day School" || schoolType === "Day cum Residential School") {
                    if (res.data.parentData.isProfileCompleted === 'true') { 
                        navigate(`/selectedSchools?childId=${btoa(`#${childId}`)}`)
                    } else {
                        // navigate("/admissionForm");
                        setPage(2)
                    }
                } else {
                    navigate(`/selectedSchools?childId=${btoa(`#${childId}`)}`)
                }
                setDisableVerifyButton(false)
            })
            .catch(err => { 
                console.log(err); 
                setDisableVerifyButton(false)
                toast.error(err.response.data.apierror.message || "Something went wrong") 
            })

    }

    const resendOtpForPhoneVerify = async () => {
        setDisableVerifyButton(true)
        setResendOtp(true);
        const payload = {
            phone: formData.phone
        }
        requestOtp(payload)
            .then(res => {
                setDisableVerifyButton(false)
                toast.success("OTP resend, Please check again");
                console.log(res)
            })
            .catch(err => {
                setDisableVerifyButton(false)
                console.log(err)
            })
    };

    return (
        <div className="model-body-col py-n30">
            <div className='otp-title-wrapp'>
                <h5 className='otp-title'>Validate Mobile Number</h5>
                <p className='otp-subtitle'>
                    Enter OTP Shared on Your registered
                    Mobile Number
                </p>
            </div>
            <div className="form-container">
                <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Form.Group>
                        <div>
                            <OtpInput
                                onChange={handleOtpChange}
                                numInputs={4}
                                isInputNum={true}
                                shouldAutoFocus
                                value={otp}
                                placeholder="----"
                                inputStyle={{
                                    width: "52px",
                                    height: "52px",
                                    caretColor: "#000000",
                                    border: '1px solid lightGrey',
                                    borderRadius: '5px',
                                    margin: '10px'
                                }}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group className="signin-fld-grp resend-wrapp">
                        <div className="otp-fields-wrapper mt-3 mb-3">
                            {resendOtp
                                ? <OtpTimer
                                    minutes={0}
                                    seconds={30}
                                    text="Resend OTP in"
                                    ButtonText="Resend OTP"
                                    resend={() => {
                                        setResendOtp(false);
                                        resendOtpForPhoneVerify();
                                    }}
                                />
                                : <Link
                                    className="resend-otp-btn"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        resendOtpForPhoneVerify();
                                    }}
                                >
                                    Send OTP Again
                                </Link>}
                        </div>
                    </Form.Group>
                    <Form.Group className="signin-fld-grp btn-wrapp" style={{display:'flex', flexDirection:'row'}}>
                            <Button onClick={handleOtpSubmit} disabled={disableVerifyButton}> Validate OTP </Button>
                            <Button
                                className='delete'
                                onClick={() => handleClose()}
                                style={{ marginLeft: '3%', border: "1px solid red", color: 'red', backgroundColor: 'white' }}
                            >
                                Cancel
                            </Button>
                    </Form.Group>
                </Form>
            </div>
        </div>
    )
}


export default FastCheckOutOtpPage;