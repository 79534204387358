import { useState } from 'react';
import GenericDialog from '../../dialogs/GenericDialog';
import { getLocalData } from '../../utils/helper';
import StudentForm from './studentForm';

import ConfirmDetailPage from './confirmDetailPage';
import OtpPage from './otpPage';


const LinkFormDialog = ({ setShowForm, showForm, setUpdateTable, }) => {

    const [data, setData] = useState([])
    const [displayCss, setDisplayCss] = useState(true)
    const [searchResponse, setSearchResponse] = useState(0)
    const [page, setPage] = useState(1)
    const [resendPayload, setResendPayload] = useState(null)

    const userId = getLocalData('userId')

    const handleClose = () => {
        setDisplayCss(true)
        setSearchResponse(0)
        setShowForm(false)
        setPage(1)
    }

    return (
        <GenericDialog
            show={showForm}
            handleClose={handleClose}
            modalHeader={page === 2 ? "Student Search Result" : "Enroll Student For Fee Payment"}
            className="enroll-student-model"
        >
            {page === 1 && <div
                className='esf-content-outer'
            >
                <StudentForm
                    setData={setData}
                    setSearchResponse={setSearchResponse}
                    setDisplayCss={setDisplayCss}
                    setResendPayload={setResendPayload}
                    searchResponse={searchResponse}
                />
                <OtpPage
                    searchResponse={searchResponse}
                    displayCss={displayCss}
                    setPage={setPage}
                    userId={userId}
                    resendPayload={resendPayload}
                />
            </div>}
            {page === 2 && <ConfirmDetailPage
                data={data}
                userId={userId}
                setShowForm={setShowForm}
                setUpdateTable={setUpdateTable}
                handleClose={handleClose}
                setData={setData}
            />}
        </GenericDialog >
    )
}

export default LinkFormDialog;