import { ActionTypes } from "../constants/action-types";

const initialState = {
  isSchoolName: false
};

export const SchoolData = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_IS_SCHOOL_NAME:
      return { ...state };
    case ActionTypes.SET_IS_SCHOOL_NAME:
      return { ...state, isSchoolName: payload };
    default:
      return state;
  }
};