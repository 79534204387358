import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import "../assets/scss/custom-styles.scss";
import Breadcrumbs from "../common/Breadcrumbs";
import Layout from "../common/layout";
import { convertQueryStringIntoObject } from "../utils/helper";

const PaymentFailed = () => {

  const navigate = useNavigate()
  const search = useLocation().search;
  const param = new URLSearchParams(search).get("param");
  const params = convertQueryStringIntoObject(param)
  const navigateHandler=()=>{
    if(params?.orderType === "ADMISSION_FORM"){
      navigate("/selectedSchools");
    }else if(params?.orderType === "REGISTRATION_FEE"){
      navigate("/userProfile");
    }else{
      navigate("/manageFee");
    }
  }
  return (
    <Layout>
      <section className="content-area about-page">
        <Container className="content-area-inner inner-page-container">
          <Row className="content-section bc-section">
            <Col className="bc-col">
              <Breadcrumbs />
            </Col>
          </Row>
          <Row className="content-section about-content-main mt-n20">
            <section className="contact-section-wrapper pd-md-0n">
              <div className="boxed-content-area payment-failed ">
                <i className="pymtFfailed-icon"></i>
                <h2>Transaction Unsuccessful</h2>
                <h4>
                  Your payment has failed for{" "}
                  {params.orderType === "ADMISSION_FORM"
                    ? `order no. ${params?.Order || ""}`
                    : params.orderType === "SCHOOL_FEE"
                      ? `month ${params?.Order || ''}`
                      : `application no. ${params?.Order || ''}`}.
                  {/* Payment Mode : {params[1] || ""} */}
                </h4>
                <h4>Reason : {params.message || ""}</h4>
                {/* <h6>(This may have occurred due to network issue, please retry after sometimes.)</h6> */}
                <div className="status-contaier-wrapp">
                  <div className="payment-details-wrapp">
                    <h6 className="payment-details-title">Transaction Details</h6>
                    {/* <div className="flex-btwen-wrapp"> */}
                    <div className="pymt-wrapp pt-0" >
                      <div className="payment-flc-btwn" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <label style={{ width: 'auto', paddingRight: '2%' }}>Status </label>
                        <span className="status-lbl-failed">Failed</span>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                  {/* <label className="viewDtls-lbl">
                    You can view the details on the
                    <a href={"/paymentHistory"}>
                      <span>
                        &nbsp;Payment History&nbsp;
                      </span>
                    </a>Page
                  </label> */}
                  <Button className="status-btn" onClick={navigateHandler}>OK, Got It!</Button>
                </div>

              </div>
            </section>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default PaymentFailed;
