import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "../assets/scss/custom-styles.scss";
import Breadcrumbs from "../common/Breadcrumbs";
import Layout from "../common/layout";
import Button from "../components/form/Button";
import { isEmpty } from "../utils/helper";
import PayTMLogo from "../assets/img/icons/Paytm_Logo.svg"

const PaymentCheckout = () => {
  const { state } = useLocation();
  const { data } = state || { odertType: ""};

  const submit = (formData) => {
    var paytm_form = document.getElementById("paytm_form");
    paytm_form.submit();
  };

  useEffect(() => {
    if (
      !isEmpty(data) &&
      !isEmpty(data.orderType) &&
      (data.orderType === "ADMISSION_FORM" || data.orderType === "SCHOOL_FEE")
    ) {
      var paytm_form = document.getElementById("paytm_form");
      paytm_form.submit();
    }
  }, []);

  return (
    <Layout>
      <section className="content-area about-page">
        <Container className="content-area-inner inner-page-container">
          <Row className="content-section bc-section">
            <Col className="bc-col">
              <Breadcrumbs />
            </Col>
          </Row>
          <Row className="content-section about-content-main mt-2">
            <section className="contact-section-wrapper">
              <div className="boxed-content-area payment-checkout">
                <div className="form-wrap-outer">
                  {!isEmpty(data) &&
                  !isEmpty(data.orderType) &&
                  data.orderType === "REGISTRATION_FEE" ? (
                    <>
                      <div className="form-container info-area">
                        <div className="title-wrap">
                          <h2>Order Summary</h2>
                        </div>
                        <div className="cell-row">
                          <span className="fld-cell fld-title">
                            Total Amount
                          </span>
                          <span className="fld-cell">
                            <label>₹{data.orderTotal}</label>
                          </span>
                        </div>
                        <div className="cell-row">
                          <span className="fld-cell fld-title">
                            Platform Fee
                          </span>
                          <span className="fld-cell">
                            <label> ₹{data.platformFee}</label>
                          </span>
                        </div>
                        <div className="cell-row">
                          <span className="fld-cell fld-title">GST 18%</span>
                          <span className="fld-cell">
                            <label> ₹{data.gst}</label>
                          </span>
                        </div>
                        <div className="cell-row">
                          <span className="fld-cell fld-title">
                            Total Payment
                          </span>
                          <span className="fld-cell">
                            <label className="totalpayment">
                              ₹{data.totalAmount}
                            </label>
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                     <div className="redirecting-msg">
                        <img src={PayTMLogo} className="img-fluid" alt="paytm-logo"/>
                        <h5 className="title">Redirecting to PAYTM payment gateway...</h5>
                        <h6 className="sub-title">Please wait while you are redirected to the paytm to initiate payment process.</h6>
                        <div className="loader-wrap">
                        <div className="one"></div>
                        <div className="two"></div>
                        <div className="three"></div>
                        <div className="four"></div>
                        <div className="five"></div>
                        </div>    
                        {/* <h6 className="gateway-sub-title">Redirecting to PAYTM payment gateway...</h6>   */}
                        <div className="assist-section-wrapp">
                          <h6 className="help-lbl"> <Link>Need help?</Link> We'll be glad to assist you! Call us now.</h6>
                          <ul>
                            <li><i className="icons email-pytm"></i> support@campuscredo.com</li>
                            <li><i className="icons phone-pytm"></i> +91-9962985155</li>
                          </ul>
                        </div>                  
                      </div>
                    </>
                  )}

                  <div className="form-container frm-area">
                    <Formik>
                      {({ errors, touched }) => (
                        <Form
                          className=""
                          id="paytm_form"
                          name="paytm"
                          action={data?.paymentProcessUrl}
                        >
                          <input
                            type="hidden"
                            name="mid"
                            value={data?.merchantId}
                          />
                          <input
                            type="hidden"
                            name="orderId"
                            value={data?.orderNumber}
                          />
                          <input
                            type="hidden"
                            name="txnToken"
                            value={data?.transactionToken}
                          />
                          {!isEmpty(data) &&
                          !isEmpty(data.orderType) &&
                          data.orderType === "REGISTRATION_FEE" ? (
                            <div className="cell-row btn-wrapper">
                              <Button
                                type="submit"
                                class="paynow-btn"
                                buttonLabel="PAY NOW"
                                onClick={submit}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </section>
          </Row>
          <ToastContainer autoClose={2000} position="top-right" />
        </Container>
      </section>
    </Layout>
  );
};

export default PaymentCheckout;
