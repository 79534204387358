import { Accordion, Button, } from "react-bootstrap";
import StringUtils from "../../utils/StringUtils";
import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import InputField from "../../components/form/InputField";
import { GENDER_OPTOPNS } from "../../constants/formContanst";
import DatePickerField from "../../components/form/DatePickerField";
import { fastCheckoutParentChild } from "../../utils/services";
import Loader, { hideLoader, showLoader } from "../../common/Loader";
import { useDispatch } from "react-redux";
import { humanize } from "../../utils/helper";


const FastCheckoutCandidateDetail = ({ detail, fetchChildAndParentDetails, setEnableDeclarationChecks }) => {

    const [edit, setEdit] = useState(false)
    const dispatch = useDispatch();

    const initialValue = {
        childId: detail.childId,
        firstName: detail.firstName,
        lastName: detail.lastName,
        gender: detail.gender,
        dateOfBirth: detail.dateOfBirth,
        medicalCondition: detail.medicalCondition
    }

    const handleSubmit = (data) => {
        showLoader(dispatch)
        fastCheckoutParentChild(data)
            .then(res => {
                fetchChildAndParentDetails(data.childId);
                setEdit(false)
                hideLoader(dispatch)
            })
            .catch(err => { hideLoader(dispatch); console.log(err) })
    }

    const getMedicalHistory = (condtion) => {
        let final
        if (condtion) {
            const medicalHistory = condtion.split(':')
            if (medicalHistory.length === 3) {
                final = `${humanize(medicalHistory[0])}: ${humanize(medicalHistory[2])} `
            } else {
                final = `${humanize(medicalHistory[0])}: ${humanize(medicalHistory[1])} `
            }
            return final
        } else {
            return 'NA'
        }
    }

    useEffect(() => {
        if (edit) {
            setEnableDeclarationChecks(true)
        } else {
            setEnableDeclarationChecks(false)
        }
    }, [edit])

    return (
        <>
            <Accordion.Header>Candidate Details</Accordion.Header>
            <Accordion.Body>
                <Formik
                    initialValues={initialValue}
                    onSubmit={(val) => handleSubmit(val)}
                >
                    {({ values, setFieldValue, errors, touched, resetForm }) => (
                        <Form className="row g-3">
                            <div className="admin-detail-row">
                                <div className="admin-detail-cell">
                                    <label>Name:</label>
                                    <span className="item-entry" style={{ display: 'flex' }}>
                                        {!edit
                                            ? initialValue?.firstName
                                            : <InputField
                                                fieldName='firstName'
                                                value={values.firstName}
                                                className='frm-cell'
                                                fieldType='text'
                                                placeholder='First Name'
                                                required
                                                errors={errors}
                                                touched={touched}
                                            />}
                                        {!edit ? " " : <div style={{ margin: ' 0 10px' }}></div>}
                                        {!edit
                                            ? initialValue?.lastName
                                            : <InputField
                                                fieldName='lastName'
                                                value={values.lastName}
                                                className='frm-cell'
                                                fieldType='text'
                                                placeholder='Last Name'
                                                required
                                                errors={errors}
                                                touched={touched}
                                            />}
                                    </span>
                                </div>
                                {/* <div className="admin-detail-cell" ></div> */}
                                {false && <div className="admin-detail-cell" style={{ alignItems: 'flex-end' }}>
                                    {!edit ?
                                        <Button onClick={() => setEdit(true)}>Edit</Button> :
                                        <div style={{ display: 'flex', }}>
                                            <Button type="submit" >SAVE</Button>
                                            <div style={{ margin: '0 5px' }}></div>
                                            <Button type="reset" onClick={() => { resetForm(); setEdit(false) }}>Reset</Button>
                                        </div>
                                    }
                                </div>}
                                <div className="admin-detail-cell">
                                    <label>Gender:</label>
                                    <span className="item-entry" >
                                        {!edit
                                            ? initialValue?.gender
                                            : <InputField
                                                fieldName='gender'
                                                value={values.gender}
                                                // className='frm-cell'
                                                fieldType='select'
                                                placeholder=''
                                                selectOptions={GENDER_OPTOPNS}
                                                errors={errors}
                                                touched={touched}
                                                required
                                            />}
                                    </span>
                                </div>
                                <div className="admin-detail-cell">
                                    <label>Date Of Birth:</label>
                                    <span className="item-entry dob-lbl" >
                                        {!edit
                                            ? initialValue?.dateOfBirth
                                            : <DatePickerField
                                                name='dateOfBirth'
                                                value={values.dateOfBirth}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                touched={touched}
                                                dateFormat='yyyy-MM-dd'
                                                required
                                                maxDate={new Date()}
                                            />}
                                    </span>
                                </div>
                                {/* <div className="admin-detail-cell"></div> */}
                                <div className="admin-detail-cell">
                                    <label>Medical Condition:</label>
                                    <span className="item-entry" >
                                        {getMedicalHistory(initialValue?.medicalCondition) || 'NA'}
                                        {/* {!edit
                                            ? (initialValue?.medicalCondition.split(':')) || 'NA'
                                            : <InputField
                                                fieldName='medicalCondition'
                                                value={values.medicalCondition}
                                                className='frm-cell'
                                                fieldType='textarea'
                                                placeholder='Medical Condition Details'
                                                errors={errors}
                                                touched={touched}
                                                required
                                            />} */}
                                    </span>
                                </div>
                            </div>
                        </Form>)}
                </Formik>
            </Accordion.Body>
            <Loader />
        </>
    )
}

export default FastCheckoutCandidateDetail;