import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import GuideScreen01 from "../../assets/img/guide/A1.PNG";
import GuideScreen02 from "../../assets/img/guide/K2.png";
import GuideScreen03 from "../../assets/img/guide/K3.png";
import GuideScreen04 from "../../assets/img/guide/K4.png";
import GuideScreen05 from "../../assets/img/guide/K5.png";
import GuideScreen06 from "../../assets/img/guide/K6.png";
import GuideScreen07 from "../../assets/img/guide/K7.png";
import GuideScreen08 from "../../assets/img/guide/K8.png";
import GuideScreen09 from "../../assets/img/guide/K9.png";
import GuideScreen10 from "../../assets/img/guide/K10.png";
import GuideScreenA9 from "../../assets/img/guide/A9.png";

const ParentManageFee = ({heading, image}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Col className="common-content right">
      <h2>How to manage school fee?</h2>
      <ListGroup as="ol" variant="flush" numbered>
        <ListGroup.Item as="li">
          <span className="screen-title">
            Sign Up and login to your CampusCredo account.
          </span>
          <div className="guide-screen">
            <img width={700} src={GuideScreen01} alt="" />
          </div>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <span className="screen-title">
          On “Dashboard”, go to “Manage Profile -{'> '}Location” tab and save your location.
          </span>
          <div className="guide-screen">
            <img width={700} src={GuideScreenA9} alt="" />
          </div>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <span className="screen-title">
          Click on “Manage Fee" tab.
          </span>
          <div className="guide-screen">
            <img width={700} src={GuideScreen02} alt="" />
          </div>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <span className="screen-title">
            Click on "Link student" button if you have not already done that.
          </span>
          <div className="guide-screen">
            <img width={700} src={GuideScreen03} alt="" />
          </div>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <span className="screen-title">
            Enter all students details and click on "Locate Student" button.
          </span>
          <div className="guide-screen">
            <img width={700} src={GuideScreen04} alt="" />
          </div>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <span className="screen-title">
            System will display the student records found.
          </span>
          <div className="guide-screen">
            <img width={700} src={GuideScreen05} alt="" />
          </div>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <span className="screen-title">
            Enter the OTP to validate mobile number registered with school.
          </span>
          <div className="guide-screen">
            <img width={700} src={GuideScreen06} alt="" />
          </div>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <span className="screen-title">
            Review and confirm student details.
          </span>
          <div className="guide-screen">
            <img width={700} src={GuideScreen07} alt="" />
          </div>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <span className="screen-title">
            Now click on “Manage Fee" tab in the dashboard to see the linked student.
          </span>
          <div className="guide-screen">
            <img width={700} src={GuideScreen08} alt="" />
          </div>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <span className="screen-title">
            Click on "Pay Fee" button to make payment for the relevent month. You can also pay for future months by selecting from the dropdown.
          </span>
          <div className="guide-screen">
            <img width={700} src={GuideScreen09} alt="" />
          </div>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <span className="screen-title">
            Once payment is completed you can view and download invoices by clicking on "Payment History" tab.
          </span>
          <div className="guide-screen">
            <img width={700} src={GuideScreen10} alt="" />
          </div>
        </ListGroup.Item>
      </ListGroup>
    </Col>
  );
};

export default ParentManageFee;
