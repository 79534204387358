import React from "react";
import Modal from "react-bootstrap/Modal";
import "../assets/scss/custom-styles.scss";
import FstchkoutLogo from "../assets/img/fact-chkout-logo.svg"
import footerCkoutLogo from "../assets/img/brand-logo-header.svg"
import { MODE_OF_PAYMENT } from "../constants/app";

const GenericDialog = ({
  children,
  modalHeader,
  className,
  show,
  handleClose,
  disableEscape
}) => {
  return (
    <Modal className={className} show={show} onHide={handleClose} backdrop={disableEscape ? "static":true } keyboard={disableEscape ? false: true}>
      <Modal.Header closeButton>{modalHeader}</Modal.Header>
      <Modal.Body className="model-body">{children}</Modal.Body>
      {/* <Modal.Footer className="footer-btn-wrapp">
      <div className="">
        <img src={footerCkoutLogo} alt="fast-chekout-logo" className="img-fluid"/>
        </div>
      <div className="button-wrap">
        <button className='save-btn btn btn-primary' type="submit" > Submit</button>
        <button className="btn cncl-btn" type="button" onClick={() => handleClose()} > Cancel</button>
      </div>        
      </Modal.Footer> */}
    </Modal>
  );
};

export default GenericDialog;
