import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';

import { OPERATORS } from "../../../constants/app";
import { getSchoolClassesData } from "../../../utils/services";
import { formatDateToDDMMYYYY } from "../../../utils/DateUtil";
import ReactDatePicker from "react-datepicker";


const FeeFilterApp = ({ setData, schoolNameId, session, filterStatus, setFilterStatus, fetchRowData }) => {

    const initialValue = {
        selectClass: '',
        schoolStudentId: '',
        dateRange: [null, null],

    }
    const [classOption, setClassOption] = useState([])
    const [selectClass, setSelecteClass] = useState(initialValue.selectClass)
    const [schoolStudentId, setSchoolStudentId] = useState(initialValue.schoolStudentId)
    const [dateRange, setDateRange] = useState(initialValue.dateRange);
    const [startDate, endDate] = dateRange;


    const buttonStatus = filterStatus
    const ClearForm = (e) => {
        setSelecteClass(initialValue.selectClass)
        setSchoolStudentId(initialValue.schoolStudentId)
        setDateRange(initialValue.dateRange)
    };

    const fetchData = async () => {
        const filters = []
        if (schoolNameId !== null && schoolNameId !== '') {
            filters.push({
                field: 'schoolId',
                operator: OPERATORS.EQUALS,
                value: schoolNameId
            });
        }
        if (session !== null && session !== '') {
            filters.push({
                field: 'session',
                operator: OPERATORS.EQUALS,
                value: session
            });
        }
        if (selectClass !== null && selectClass !== '') {
            filters.push({
                field: 'classId',
                operator: OPERATORS.EQUALS,
                value: selectClass
            });
        }
        if (schoolStudentId !== null && schoolStudentId !== '') {
            filters.push({
                field: 'schoolStudentId',
                operator: OPERATORS.EQUALS,
                value: schoolStudentId
            });
        }
        if (startDate !== null && startDate !== '' && endDate !== null && endDate !== '') {
            filters.push({
                field: 'feePaymentDate',
                operator: OPERATORS.BETWEEN,
                values: [formatDateToDDMMYYYY(startDate), formatDateToDDMMYYYY(endDate)]
            });
        }
        fetchRowData(filters)
    }

    const handleApply = async (e) => {
        e.preventDefault()
        fetchData()
    }

    const fetchClassesOption = async (schoolNameId) => {
        try {
            const response = await getSchoolClassesData(schoolNameId)
            setClassOption(response.data)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (schoolNameId) {
            fetchClassesOption(schoolNameId)
        }
        if (schoolNameId === '' && session === '') {
            setSelecteClass(initialValue.selectClass)
            setSchoolStudentId(initialValue.schoolStudentId)
            setFilterStatus(true)
        }
    }, [schoolNameId])

    useEffect(()=>{
        if (selectClass ==='' && schoolStudentId ==='' && startDate === null &&  endDate === null && schoolNameId !== '' && session !== ''){
            fetchData()
        }
    },[selectClass, schoolStudentId, startDate, endDate])


    return (
        <div className='filterpanel'>
            <div className='filter-head'>
                <span className='filter-title'>
                    <i className='icons filter-icon'></i>
                    <label>Filters</label>
                </span>
                <Link href='' onClick={ClearForm}>Clear All</Link>
            </div>
            <div className='filter-form-area'>
                <Form>
                    <Form.Group className='form-element-group' controlId=''>
                        <Form.Label className='form-label'>Class</Form.Label>
                        <Form.Select
                            value={selectClass}
                            disabled={buttonStatus}
                            onChange={(e) => {
                                setSelecteClass(e.target.value);
                            }}
                            size="sm"
                        >
                            <option value={''}>Select Class</option>
                            {classOption.map((val, i) => <option key={i} value={val.classId}>{val.className}</option>)}

                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='form-element-group' controlId=''>
                        <Form.Label className='form-label'>School Student Id</Form.Label>
                        <Form.Control
                            placeholder="Enter Id"
                            disabled={buttonStatus}
                            type='text'
                            className='inner-container'
                            value={schoolStudentId}
                            onChange={(e) => setSchoolStudentId(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className='form-element-group' controlId=''>
                        <Form.Label className='form-label'>Application Date Range</Form.Label>
                        <ReactDatePicker
                            selectsRange={true}
                            disabled={buttonStatus}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            placeholderText={startDate ? `${formatDateToDDMMYYYY(startDate)}- ${formatDateToDDMMYYYY(endDate)}` : 'Select Date Range'}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                            onFocus={e => e.target.blur()}
                            dateFormat={"dd/MM/yyyy"}
                            customInput={
                                <Form.Control
                                    size='sm'
                                    type='text'
                                />
                            }
                        />
                    </Form.Group>
                    <Form.Group className='filter-item btn-wrap'>
                        <div className=''>
                            <button
                                onClick={handleApply}
                                className="btn applyFilter"
                                disabled={!(!(selectClass === '') || !(schoolStudentId === '') || !(startDate === null)|| !(endDate === null))}
                            >
                                Apply Filter
                            </button>
                        </div>
                    </Form.Group>

                </Form>

            </div>
        </div>
    )
}

export default FeeFilterApp;