import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as DownloadIcon } from "../../assets/img/icons/download.svg";
import schoolpic01 from "../../assets/img/school-picture/boarding-icon.jpg";
import Loader, { hideLoader, showLoader } from "../../common/Loader";
import {
  PARENT_APPLICATION_STATUS,
  SCHOOL_APPLICATION_STATUS,
} from "../../constants/app";
import UploadRequestedDocDialog from "../../dialogs/uplaodRequestedDocDialog";
import RestEndPoint from "../../redux/constants/RestEndpoints";
import RESTClient, { baseURL } from "../../utils/RestClient";
import { getIpAddress, getStatusLabel } from "../../utils/helper";
import {
  downloadApplicationOnParentDashboard,
  processOrderAfterPayment,
  registrationCheckout,
} from "../../utils/services";
import AcceptRejectApplication from "./AcceptRejectApplication";
import ApplicationTimeline from "./ApplicationTimeline";

const AppliedSchools = ({ application, setApplications }) => {
  const [showTimeline, setShowTimeline] = useState(false);
  const [acceptButton, setAcceptButton] = useState(false);
  const [showRequestedDocDialog, setShowRequestedDocDialog] = useState(false);
  const [componentKey, setComponentKey] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function downloadApplicationOnDashboard(applicationId) {
    downloadApplicationOnParentDashboard(applicationId);
  }

  const getBadgeClassName = (status) => {
    switch (status) {
      case PARENT_APPLICATION_STATUS.AT_PI_SCHEDULED:
        return "violet-badge";
      case SCHOOL_APPLICATION_STATUS.RECEIVED:
      case PARENT_APPLICATION_STATUS.SUBMITTED:
        return "blue-badge";
      case SCHOOL_APPLICATION_STATUS.DECLINED:
      case PARENT_APPLICATION_STATUS.REJECTED:
      case SCHOOL_APPLICATION_STATUS.REVOKED:
      case SCHOOL_APPLICATION_STATUS.DENIED:
        return "red-badge";
      case SCHOOL_APPLICATION_STATUS.APPROVED:
      case PARENT_APPLICATION_STATUS.ACCEPTED:
        return "green-badge";
      case SCHOOL_APPLICATION_STATUS.UNDER_REVIEW:
      case SCHOOL_APPLICATION_STATUS.UNDER_FINAL_REVIEW:
        return "orange-badge";
      default:
        return "blue-badge";
    }
  };

  const responseHandler = async (e, orderId) => {
    try {
      const formData = new FormData();
      formData.append("response", JSON.stringify(e));
      const response = await processOrderAfterPayment(formData, orderId);
      console.log(response);
      navigate(`/paymentFailed?${response.data}`);
    } catch (e) {
      console.log(e);
      toast.error("Payment Cancelled");
    }
  };

  async function acceptApplication() {
    setAcceptButton(true);
    showLoader(dispatch);
    try {
      const ip = await getIpAddress();
      const payload = {
        childId: application.applicationId,
        ipAddress: ip,
        deviceUserAgent: window.navigator.userAgent,
      };
      const response = await registrationCheckout(payload);
      navigate("/paymentCheckout", { state: { data: response.data } });
      setAcceptButton(false);
      hideLoader(dispatch);
    } catch (error) {
      console.log("Error", error);
      toast.error("Payment is failed. Please try later");
      setAcceptButton(false);
      hideLoader(dispatch);
    }
  }

  async function rejectApplication() {
    try {
      const rejectAppRes = await RESTClient.post(
        RestEndPoint.UPDATE_APPLICATION_STATUS,
        {
          applicationId: application.applicationId,
          childId: application.childId,
          applicationStatus: "DENIED",
        }
      );
      setShowTimeline(false);
      const response = await RESTClient.get(
        RestEndPoint.GET_APPLICATION_LIST + `/${application.childId}`
      );
      setApplications(response.data);

      toast.success("Application status updated successfully.");
    } catch (error) {
      toast.error(RESTClient.getAPIErrorMessage(error));
    }
  }

  function getApplicationStatusMessage(history, index) {
    if (
      application.applicationStatus === SCHOOL_APPLICATION_STATUS.APPROVED &&
      history?.applicationStatus?.toUpperCase() ===
        SCHOOL_APPLICATION_STATUS.APPROVED &&
      index === application.applicationDataHistory.length - 1
    ) {
      return (
        <AcceptRejectApplication
          rejectApplication={rejectApplication}
          acceptApplication={acceptApplication}
        />
      );
    }
  }
  function uploadRequestedDocument() {
    if (
      application.applicationStatus ===
      SCHOOL_APPLICATION_STATUS.DOCUMENT_REQUESTED
    ) {
      setShowRequestedDocDialog(true);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Col className="right content" key={componentKey}>
      <div className="row-items application-block">
        <div className="application-row">
          <div className="application-inner-wrap">
            <div className="col-item left">
              <div className="school-info-main">
                <div
                  className="info-item school-logo-wrap"
                  style={{
                    height: "132px",
                    width: "132px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Card.Img
                    className="school-logo"
                    alt={application.schoolName}
                    src={
                      application.schoolThumbnailImgLink
                        ? baseURL + application.schoolThumbnailImgLink
                        : schoolpic01
                    }
                  />
                </div>

                <div className="info-item school-info-exerpts">
                  <div className="school-name">{application.schoolName}</div>
                  <ListGroup className="school-type">
                    <ListGroup.Item>{application.board}</ListGroup.Item>
                    <ListGroup.Item>
                      {application.mediumOfInstruction}
                    </ListGroup.Item>
                    <ListGroup.Item>{application.gender}</ListGroup.Item>
                  </ListGroup>

                  <div className="moreinfo-block">
                    <div className="app-id">
                      <label>
                        Application ID : {application.applicationNumber}
                      </label>
                    </div>
                    <div className="row-item">
                      <span className="cell-item">Applied To Class:</span>
                      <span className="cell-item">
                        <strong>{application.className}</strong>
                      </span>
                    </div>
                    <div className="row-item">
                      <span className="cell-item">Application Fee Paid:</span>
                      <span className="cell-item">
                        <strong>{application.formFee}</strong>
                      </span>
                    </div>
                    <div className="row-item">
                      <span className="cell-item">Applied For Session:</span>
                      <span className="cell-item">
                        <strong>{application.admissionSession}</strong>
                      </span>
                    </div>
                    <div className="row-item">
                      <span className="download-option">
                        <a
                          href="javascript:void(0)"
                          style={{ alignItems: "baseline" }}
                          onClick={() => {
                            downloadApplicationOnDashboard(
                              application.applicationId
                            );
                          }}
                        >
                          <label style={{ cursor: "pointer" }}>
                            Download Application Form
                          </label>{" "}
                          <DownloadIcon />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-item right">
              <div className="application-status">
                <span
                  className={
                    "badge grey-badge " +
                    getBadgeClassName(application.applicationStatus)
                  }
                  onClick={() => {
                    uploadRequestedDocument();
                  }}
                >
                  {getStatusLabel(application.applicationStatus)}
                  {application.applicationStatus ===
                    SCHOOL_APPLICATION_STATUS.DOCUMENT_REQUESTED && (
                    <i
                      className="icons link-icon"
                    ></i>
                  )}
                </span>
              </div>
              <div className="app-timeline-stack">
                {application.applicationDataHistory?.length ? (
                  <div className="timeline-list">
                    <div className="timeline-info-panel">
                      {application.applicationDataHistory.map(
                        (history, index) => {
                          return (
                            <div
                              className="timeline-row"
                              key={"timeline_" + index}
                            >
                              <div className="timeline-items">
                                {getApplicationStatusMessage(history, index)}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="app-timeline-control">
                <Link onClick={() => setShowTimeline((val) => !val)}>
                  <label>View Status timeline</label>{" "}
                  <i
                    className={
                      "icons " +
                      (showTimeline ? "arrowdown-icon" : "arrowright-icon")
                    }
                  ></i>
                </Link>
              </div>
            </div>
          </div>
          {showTimeline ? (
            <ApplicationTimeline
              application={application}
              setApplications={setApplications}
              setShowTimeline={setShowTimeline}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <UploadRequestedDocDialog
        requestedDocument={application.documentsRequested}
        documentComment ={application.documentsRequestedComment}
        show={showRequestedDocDialog}
        handleClose={() => {
          setShowRequestedDocDialog(false);
          setComponentKey((prevKey) => prevKey + 1);
        }}
        applicationId={application.applicationId}
        childId={application.childId}
        setApplications={setApplications}
      />
      <Loader />
    </Col>
  );
};

export default AppliedSchools;
