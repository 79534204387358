import { ActionTypes } from "../constants/action-types";
export const setIsSchoolName = (location) => { 
    return (dispatch) => {
        dispatch({ type: ActionTypes.SET_IS_SCHOOL_NAME, payload: location });
    };
};

export const getIsSchoolName = () => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.GET_IS_SCHOOL_NAME});
    };
};