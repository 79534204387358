import { useEffect, useState } from "react";
import TableComponent from "../../../common/TableComponent"
import moment from "moment";
import { PARENT_APPLICATION_STATUS, SCHOOL_APPLICATION_STATUS } from "../../../constants/app"
import { getStatusLabelForSchool, humanize } from "../../../utils/helper"
import eyeFill from "../../../assets/img/eye-fill.svg"
import ApplicationTimeline from "../../../components/userProfile/ApplicationTimeline";
import Col from "react-bootstrap/Col";
import { Accordion, Table } from "react-bootstrap";
import GenericDialog from "../../../../src/dialogs/GenericDialog"
import { downloadInvoiceReceipt, getMoneyReciepts } from "../../../utils/services";
import { ReactComponent as DownloadIcon } from "../../../assets/img/icons/download.svg"
import { toast } from "react-toastify";


const ShowSuperAdminApp = ({ rowsData, setActivePage, totalRows, activePage }) => {
  const [selectedRows, setSelectedRows] = useState({});
  const [showTimeLine, setShowTimeLIne] = useState(false)
  const [timeLineData, setTimeLineData] = useState({})
  const [showInvoices, setShowInvoices] = useState(false)
  const [invoicesList, setInvoicesList] = useState([])

  const getClassName = (status) => {
    switch (status) {
      case SCHOOL_APPLICATION_STATUS.AT_PI:
        return "violet-label";
      case SCHOOL_APPLICATION_STATUS.RECEIVED:
        return "blue-label";
      case SCHOOL_APPLICATION_STATUS.DECLINED:
      case PARENT_APPLICATION_STATUS.REJECTED:
      case SCHOOL_APPLICATION_STATUS.REVOKED:
      case SCHOOL_APPLICATION_STATUS.DENIED:
        return "red-label";
      case SCHOOL_APPLICATION_STATUS.APPROVED:
      case PARENT_APPLICATION_STATUS.ACCEPTED:
        return "green-label";
      case SCHOOL_APPLICATION_STATUS.UNDER_REVIEW:
      case SCHOOL_APPLICATION_STATUS.UNDER_FINAL_REVIEW:
        return "orange-label";
      default:
        return "black-label";
    }
  };

  const columns = [
    {
      accessor: 'applicationNumber',
      Header: 'Application ID',
    },
    {
      accessor: '',
      Header: 'Applicant Name',
      Cell: ((e) => {
        return (
          <div>
            <span style={{ color: '#41285F' }}>{`${e.row.original?.firstName} ${e.row.original?.lastName}`}</span>
          </div>
        );
      })
    },
    {
      accessor: 'mobileNumber',
      Header: 'Mobile Number',
    },
    {
      accessor: '',
      Header: 'Application Date',
      Cell: ((e) => {
        return <span>{moment(e.row.original.submissionDate).format('DD/MM/YYYY')}</span>;
      })
    },
    {
      accessor: 'schoolName',
      Header: 'School Name',
    },
    {
      accessor: 'className',
      Header: 'Class Name',
    },
    {
      accessor: 'admissionSession',
      Header: 'Admission Session',
    },
    {
      accessor: 'applicationStatus',
      Header: 'Application Status',
      Cell: ((e) => {
        const applicationStatus = e.row.original?.applicationStatus;
        return <span className={getClassName(applicationStatus)}>{getStatusLabelForSchool(applicationStatus)}</span>;
      })
    },
    {
      accessor: '',
      Header: 'Timeline',
      Cell: ((e) => {
        const data = e.row.original
        return <button onClick={(e) => { e.preventDefault(); handleView(data) }}> <img src={eyeFill} /> </button>
      }),
    },
    {
      accessor: '',
      Header: 'Money Receipts',
      Cell: ((e) => {
        const data = e.row.original.applicationId
        return <button onClick={() => fetchMoneyReciepts(data)}><img src={eyeFill} /> </button>
      })
    },
  ];

  const fetchMoneyReciepts = async (data) => {
    setShowInvoices(true)
    try {
      const response = await getMoneyReciepts(data)
      const keys = Object.keys(response.data)
      const temp = keys.map((val, i) => {
        const obj = {}
        obj['feeTypeName'] = val;
        obj['orderId'] = response?.data[val];
        return obj
      })
      setInvoicesList(temp)
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.apierror.message)
    }
  }

  const downloadReceiptViaOrderId = (orderId) => {
    downloadInvoiceReceipt(orderId, 'MoneyReceipt')
  }

  const handleView = (data) => {
    setTimeLineData(data)
    setShowTimeLIne(!showTimeLine)
  }

 

  return (
    <>
      <div className='inner-content-wrap'>
        <div className='table-wrapper-outer'>
          <TableComponent
            columns={columns}
            data={rowsData}
            showSelectedAll={false}
            selectedRows={selectedRows}
            onSelectedRowsChange={setSelectedRows}
            isPagination={true}
            setActivePage={setActivePage} 
            totalRows={totalRows} 
            activePage={activePage}
          />
          <GenericDialog className="review-admission-modal add-child-model"
            show={showTimeLine}
            handleClose={() => setShowTimeLIne(false)}
            modalHeader=""
          >
            <Accordion >
              <Accordion.Item>
                <Accordion.Body>
                  <Col className="right content">
                    <div className="row-items application-block">
                      <div className="application-row">
                        <ApplicationTimeline application={timeLineData} setApplications={setTimeLineData} setShowTimeline={showTimeLine} />
                      </div>
                    </div>
                  </Col>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </GenericDialog>
          <GenericDialog className="joinus-model"
            show={showInvoices}
            handleClose={() => setShowInvoices(false)}
            modalHeader={<strong>Money Receipts</strong>}
          >
            <div className="application-row">
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Order Id</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {invoicesList.length > 0 ? invoicesList.map((val, i) =>
                    <tr key={i}>
                      <td>{humanize(val.feeTypeName)}</td>
                      <td>{val.orderId}</td>
                      <td style={{
                        display: 'flex',
                        justifyContent: 'center',
                        cursor: "pointer",
                      }}
                      >
                        <DownloadIcon onClick={()=>downloadReceiptViaOrderId(val.orderId)}/>
                      </td>
                    </tr>

                  ) :
                  <tr>
                    <td colSpan='3' style={{ textAlign: "center" }}>No Data Found</td>
                  </tr>
                
                }
                  {/* <td style={{ textAlign: 'center' }}>Admission Form</td>
                    <td></td>
                    <td>
                      <DownloadIcon
                        className=""
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          cursor: "pointer",
                        }}
                      />
                    </td> */}
                </tbody>
              </Table>
            </div>
          </GenericDialog>
        </div >
      </div>

    </>
  )
}
export default ShowSuperAdminApp