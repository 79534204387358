import { useEffect } from 'react';
import './App.css';
import AppRoutes from "./routes";
function App() {
  
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);

  return (
    <div className="App">
      <AppRoutes/>
    </div>
  );
}

export default App;
