import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import NoRecordsFound from "../common/NoRecordsFound";
import { requestDocument } from "../data/validationSchema";
import RestEndPoint from "../redux/constants/RestEndpoints";
import RESTClient from "../utils/RestClient";
import { humanize } from "../utils/helper";
import { updateApplicationStatus } from "../utils/services";
import GenericDialog from "./GenericDialog";

const RequestDocumentDialog = ({
  show,
  applicationId,
  handleClose,
  classId,
  callAllApi,
  sessionValue,
  setApiError,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [emptyListError, setEmptyListError] = useState("");
  const [documents, setDocuments] = useState([]);
  const [otherDocument, setOtherDocument] = useState(false);
  const [key, setKey] = useState("student");

  const getApplicationDetail = async (applicationId) => {
    try {
      const response = await RESTClient.get(
        RestEndPoint.APPLICANT_DETAIL + `/${applicationId}`
      );
      if (response.data?.applicantDocument !== "") {
        setDocuments(response.data?.applicantDocument || []);
      }
    } catch (error) {
      setDocuments([]);
    }
  };

  const requestDocumentApiCall = (payLoadArr, comment) => {
    setSubmitting(true);
    let payload = {
      applicationId: applicationId,
      applicationStatus: "DOCUMENT_REQUESTED",
      documentsRequestedComment: comment,
      documentRequestedDtos: payLoadArr.map((val)=>{
        const obj = {
          "documentName" : val[0],
          "category" : val[1] 
        }
        return obj
      })
    };
    updateApplicationStatus(payload)
      .then((response) => {
        setSubmitting(false);
        callAllApi(classId, sessionValue);
        handleClose();
      })
      .catch((res) => {
        setSubmitting(false);
        const messageData = res?.response?.data?.apierror?.message;
        setApiError(messageData);
        // handleClose();
      });
  };
  const handleSubmitDocumentRequest = (values, resetForm) => {
    values = {
      ...values,
      groups: values.groups.filter((e) => e),
    };
    const payLoadArr = values.groups.map((val) => val.split('##'))
    if (values.other && !values.groups.length) {
      setEmptyListError("");
      requestDocumentApiCall(payLoadArr, values.comment);
      const otherDocs = values.other.split(',');
      otherDocs.map(val => {
        const trimVal = (val).trim();
        if (trimVal !== '') {
          payLoadArr.push([trimVal, 'other'])
        }
      })

      resetForm();
    } else if (values.groups.length > 0) {
      setEmptyListError("");
      if (values.other) {
        const otherDocs = values.other.split(',');
        otherDocs.map(val => {
          const trimVal = (val).trim();
          if (trimVal !== '') {
            payLoadArr.push([trimVal, 'other'])
          }
        })
      }
      requestDocumentApiCall(payLoadArr, values.comment);
      resetForm();
    } else {
      setEmptyListError("Please select atleast one document");
    }
  };
  useEffect(() => {
    setEmptyListError("");
    setOtherDocument(false);
    if (applicationId && applicationId !== "") {
      getApplicationDetail(applicationId);
    }
  }, [applicationId, handleClose]);

  return (
    <GenericDialog
      show={show}
      className="confirmation-modal"
      handleClose={handleClose}
      modalHeader="Request Documents"
    >
      <div className="model-body-col">
        <div className="message-content" style={{ marginBottom: "10px" }}>
          <label className="form-label">
            Please Select the required document,
          </label>
        </div>
        <div className="tab-wrapper">
          <Formik
            initialValues={{
              groups: [],
              otherCheckbox: false,
              other: "",
              comment: "",
            }}
            validationSchema={() => requestDocument(otherDocument)}
            onSubmit={(values, { resetForm }) => {
              setOtherDocument(false);
              handleSubmitDocumentRequest(values, { resetForm });
            }}
          >
            {({ values, setFieldValue, errors, touched, resetForm }) => (
              <Form>
                {emptyListError && (
                  <span name="groups" component="div" className="text-danger">
                    {emptyListError}
                  </span>
                )}
                <div className="document-container">
                  {documents.length > 0 ? (
                    <div>
                      <div className="checkBox-wrapper">
                        {documents.map((document, index) => (
                          <div
                            key={"childDoc_" + index}
                            className="tab-outer-wrap"
                          >
                            <input
                              type="checkbox"
                              name="groups"
                              onChange={(event) => {
                                const value = event.target.checked
                                  ? document.documentName + '##' + document.category
                                  : "";
                                setFieldValue(`groups.${index}`, value);
                              }}
                            />
                            {humanize(document.documentName)}
                          </div>
                        ))}
                      </div>
                      <div className="other-wrapp">
                        <input
                          type="checkbox"
                          name="otherCheckbox"
                          onChange={(e) => setOtherDocument(e.target.checked)}
                        />
                        Other{" "}
                        {otherDocument && (
                          <input
                            name="other"
                            type="text"
                            placeholder="Enter Comma Separated Name"
                            onChange={(e) =>
                              setFieldValue("other", e.target.value)
                            }
                          />
                        )}
                        {errors.other && touched.other && (
                          <span className="text-danger">{errors.other}</span>
                        )}
                      </div>
                      <div className="cmnt-wrapper">
                        <label className="form-label">Please add comment</label>
                        <input
                          name="comment"
                          type="text"
                          placeholder="Enter comment here"
                          onChange={(e) =>
                            setFieldValue("comment", e.target.value)
                          }
                        />
                      </div>
                      {errors.comment && touched.comment && (
                        <span className="text-danger">{errors.comment}</span>
                      )}
                      <Button
                        className="submit-btn"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </div>
                  ) : (
                    <NoRecordsFound message="No documents uploaded yet." />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </GenericDialog>
  );
};

export default RequestDocumentDialog;
