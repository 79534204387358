
import { useState } from 'react';
import { Button, Form } from "react-bootstrap";
import OtpInput from 'react-otp-input';
import OtpTimer from "otp-timer";
import { toast } from 'react-toastify';

import { linkParentStudent, verifyParentStudent } from '../../utils/services';
import { Link } from 'react-router-dom';


const OtpPage = ({ searchResponse, displayCss, setPage, userId, resendPayload, setData }) => {

    const [otp, setOtp] = useState("");
    const [resendOtp, setResendOtp] = useState(false);

    const handleOtpChange = (otps) => {
        setOtp(otps);
    };


    const handleOtpSubmit = () => {
        const payload = {
            "userId": userId,
            "otp": otp
        }
        verifyParentStudent(payload)
            .then(res => setPage(2))
            .catch(err => {
                toast.error(err?.response?.data?.apierror?.message || "Please Check OTP")
            })
    }

    const resendOtpForPhoneVerify = () => {
        setResendOtp(true);
        console.log('payload', resendPayload)
        linkParentStudent(resendPayload)
            .then(res => {setData(res?.data) })
            .catch(err => console.log(err))
    }

    return (
        <div className='esf-content-inner succes-error-block'>
            {(searchResponse !== 2)
                ? <div className='no-record-panel'
                    style={{
                        display: `${displayCss ? 'none' : ''}`
                    }}

                >
                    <h6 style={{ color: 'red' }}>Oops! Student records does not match </h6>
                    <h6 className='fs-14n' style={{ color: 'grey' }}>Sorry we are unable to match information provided with our records. Please try again or talk send email to support@campuscredo.com for further assistance.</h6>
                </div>
                : <div className='success-block'

                    style={{
                        border: '1px solid lightGrey',
                        backgroundColor: 'rgba(242,242,242, .4) ',
                        borderRadius: '5px',
                        width: '100%',
                        // height: '100%',
                        padding: '30px',
                        marginBottom: '10px',
                        display: `${displayCss ? '' : 'none'}`
                    }}

                >
                    <h6 style={{ color: 'green' }}>Student Verified Successfully </h6>
                    <h6 style={{ color: 'grey' }}>Student's information is successfully matched with our records. Now proceed further please enter OTP recieved in your registered mobile number. </h6>
                </div>
            }
            <div className='cell-block validate-otp-block'>
                <h5 style={{ display: 'flex', justifyContent: 'center', }}>Validate Mobile Number</h5>
                <p style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                    Enter OTP Shared on Your registered
                    Mobile Number
                </p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Form >
                        <Form.Group style={{ display: 'flex', justifyContent: 'center' }}>
                            <div>
                                <div >
                                    <OtpInput
                                        onChange={handleOtpChange}
                                        numInputs={4}
                                        isDisabled={!(searchResponse === 2)}
                                        isInputNum={true}
                                        shouldAutoFocus
                                        value={otp}
                                        placeholder="----"
                                        inputStyle={{
                                            width: "52px",
                                            height: "52px",
                                            caretColor: "#000000",
                                            border: '1px solid lightGrey',
                                            borderRadius: '5px',
                                            margin: '10px'
                                        }}
                                    />
                                </div>
                            </div>
                        </Form.Group>
                        {(searchResponse === 2) && <Form.Group
                            className="signin-fld-grp"
                        >
                            <div className="otp-fields-wrapper mt-3 mb-3" style={{display:'flex', justifyContent:'center'}}>
                                {resendOtp
                                    ? <OtpTimer
                                        minutes={0}
                                        seconds={30}
                                        text="Resend OTP in"
                                        ButtonText="Resend OTP"
                                        resend={() => {
                                            setResendOtp(false);
                                            resendOtpForPhoneVerify();

                                        }}
                                    />
                                    : <Link
                                        className="resend-otp-btn"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            resendOtpForPhoneVerify();
                                        }}
                                    >
                                        Send OTP Again
                                    </Link>}
                            </div>
                        </Form.Group>}
                        <Form.Group style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button
                                disabled={!(searchResponse === 2)}
                                style={{
                                    border: `${searchResponse === 2 ? '' : '1px solid lightGrey'}`,
                                    backgroundColor: `${searchResponse === 2 ? '' : 'lightGrey'}`
                                }}
                                onClick={handleOtpSubmit}
                            >
                                Validate OTP
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </div>
    )
}


export default OtpPage;